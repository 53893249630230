import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Paper } from "@mui/material"
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    registerables as registerablesJS
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import _ from 'lodash';

import { getHireResignGraph, getProductionScopeGraphData } from '../../../redux/actions';

import useStyles from './styles'
import { getCookie } from '../../../services/cookie';

ChartJS.register(...registerablesJS)
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const ProductionScopeGraph = (props) => {
    const classes = useStyles()
    const dispatch = useDispatch();
    const role = getCookie("role");
    const companyId = getCookie("companyId");
    const scope = props.scope === 1 ? 'Scope 1' : props.scope === 2 ? 'Scope 2' : 'Scope 3'
    const scopeVar = props.scope === 1 ? 'getProductionScope1GraphData' : props.scope === 2 ? 'getProductionScope2GraphData' : 'getProductionScope3GraphData'
    const colourMap = {
        1:{
            production:'#6FBFBA',
            emission: '#FFC757'
        },
        2:{
            production:'#6FBFBA',
            emission: '#846BB5'
        },
        3:{
            production:'#846BB5',
            emission: '#FFC757'
        }
    }
    const employeeData = useSelector(state => state.dashboard[scopeVar].data);
    const employeeDataStatus = useSelector(state => state.dashboard[scopeVar].data.status);
    const unit = useSelector(state => state.dashboard[scopeVar].data.unit);
    console.log(employeeData, "nikl", scopeVar, scope);
    useEffect(() => {
        !props.refreshContent &&  dispatch(getProductionScopeGraphData(props.filter,role,companyId, scope));
    }, [dispatch, props.filter]);

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                labels: {
                    fontColor: 'rgba(0,0,0,.4)',
                },
                align: 'end',
                position: 'bottom',
            },
            title: {
                align: 'start',
                display: true,
                text: `${scope} Emissions per unit Produce (${unit?unit:'Tonnes'})`,
                color: 'black',
                padding: {
                    bottom: 20,
                },
                font: {
                    size: 18,
                    family: 'Inter',
                    weight: '400',
                },
            },
        },
        scales: {
            y: {
                title: {
                    display: true,
                    text: `Scope ${props.scope} Emissions & Produce`,
                    color: '#4F4F4F',
                    font: {
                        size: 12,
                        family: 'Inter',
                        weight: '400',
                    },
                },
                ticks: {
                    color: "black",
                    stepSize: 10, 
                    beginAtZero: true 
                },
                grid: {
                    display: false,
                  },
            },
            x:{
                title: {
                    display: true,
                    text: 'Produce',
                    color: '#4F4F4F',
                    font: {
                        size: 12,
                        family: 'Inter',
                        weight: '400',
                    },
                },
                ticks:{
                    color: "black",
                },
                grid: {
                    display: false,
                  },
            }
        },
    };
    

    const data = {
        labels: _.map(employeeData?.production_data, (item) => item.year),
        datasets: [
            {
                label: 'Production',
                data: _.map(employeeData?.production_data, (item) => item.production_value),
                color: "black",
                backgroundColor: colourMap[props.scope].production,
                borderColor: colourMap[props.scope].production, 
                pointBackgroundColor: colourMap[props.scope].emission,
                pointRadius: 5
            },
            {
                label: `${scope} Emission`,
                data: _.map(employeeData?.scope_data, (item) => item.total_co2e),
                color: "black",
                backgroundColor: colourMap[props.scope].emission,
                borderColor: colourMap[props.scope].emission, 
                pointBackgroundColor: colourMap[props.scope].production,
                pointRadius: 5
            },
        ],
    };


    return (
        <Paper className={classes.container} >
            {employeeDataStatus === true ? 
                <Line options={options} data={data} height={360} width={560} />
                 :
                "Data loading..."
            }
        </Paper >
    )
}

export default ProductionScopeGraph