import { BorderColor, Padding } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
    "@media (min-width: 1200px)": {
      maxWidth: "none",
    },
  },
  loader: {
    width: "100%",
    height: 100,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: theme.spacing(5),
  },
  buttonPrimary: {
    background: theme.palette.Primary.background,
    textTransform: "capitalize",
    fontWeight: 400,
    whiteSpace: "nowrap",
    "&:hover": {
      backgroundColor: theme.palette.Primary.hoverButton,
      color: theme.palette.Primary.light,
    },
    width: "fit-content",
    height: 37,
    fontSize: 14,
    marginLeft: theme.spacing(2),
  },
  box: {
    backgroundColor: "white",
    padding: theme.spacing(5),
    borderRadius: 8,
  },
  popUpdialog: {
    width: "50% !important",
    textAlign: "center",
    "& .MuiPaper-root.MuiDialog-paper": {
      maxWidth: "none",
      backgroundColor: "white",
    },
  },
  // gridItem: {
  //   margin: theme.spacing(5, 0),
  // },
  inputGrid: {
    margin: theme.spacing(5, 0),
    paddingBottom: theme.spacing(8),
  },
  sectionTitle:{
    fontSize: 18,
    marginTop: theme.spacing(5),
    textDecoration: "underline"
  },
  mainQuestion:{
    // paddingLeft: theme.spacing(3),
    fontSize: 16,
    margin: theme.spacing(3,0,0,0),
  },
  subQuestion:{
    // paddingLeft: theme.spacing(3),
    margin: theme.spacing(3,0,0,0),
    fontSize: 14,
    fontWeight: 500,
  },
  textAreaContainer: {
    margin: theme.spacing(8, 0),
    height: "auto",
    "& .css-1ps9hi4-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled":{
      WebkitTextFillColor: "black",
    }
  },
  inputContainer:{
    width: "100%",
    marginBottom: theme.spacing(8),
    height: '15px',
    margin: '12px 5px',
    "& .MuiInputBase-root":{
      padding: "0px"
    },
    // "& .MuiFormControl-root > label" :{
    //   marginBottom : '5px'
    // }
  },
  inputContainerFreeMargin:{
    width: "100%",
    marginBottom: theme.spacing(8),
    height: '15px',
    margin: '0px 5px',
    "& .MuiInputBase-root":{
      padding: "0px"
    },
    // "& .MuiInputBase-root > label" :{
    //   marginBottom : '5px'
    // }
  },
  selectCheckbox: {
    padding: 0,
    color: "#8C2148",
    borderRadius: "20%",
    margin: theme.spacing( 2 , 5 , 2, 0),
    "& .MuiFormControlLabel-root.MuiFormControlLabel-label.Mui-disabled":{
      color: "black"
    }
  },
  label:{
    "& .MuiFormControlLabel-label.Mui-disabled":{
      color: "black !important",
      fontSize: 14,
    }
  },
  headerCell: {
    border: "0.5px solid #5A404E",
  },
  tableBodyCell: {
    border: "0.5px solid #5A404E",
    fontWeight: 550,
  },
  tableTitle: {
    textAlign: "center",
  },
  tableContainer: {
    marginTop: theme.spacing(5),
    border: "1px solid black",
    borderRadius: 8,
    paddingBottom: "0px !important",
    minHeight: "none",
    maxHeight: "none",
    "& .MuiTable-root":{
      paddingBottom: "0px !important",
    }
  },
  upload:{
    margin: theme.spacing(3,0)
  },
  supInfo:{
    display: 'flex',
    // justifyContent: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  supInfoHalfContent:{
    width:'50%'
  },
  supInfoFullContent:{
    width:'100%'
  },
  addressInner:{
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '10px 25px',
    "& .MuiAutocomplete-root":{
      marginLeft: '5px'
    },
    "& .MuiAutocomplete-root > .MuiFormControl-root":{
      width: '101%'
    },
    "& .MuiInputBase-root":{
      maxHeight : '53px'
    },
    "& .MuiBox-root > .MuiAutocomplete-root > .MuiFormControl-root > .MuiInputBase-root > .MuiOutlinedInput-notchedOutline" : {
      borderColor: "rgba(0, 0, 0, 0.23)"
    },
    "& .MuiBox-root:hover fieldset": {
        borderColor: theme.palette.Primary.main,
      },
      "&.Mui-focused fieldset": {
        borderWidth: 12,
        borderColor: theme.palette.Primary.main,
      },
      // "& .MuiBox-root":{
      //   margin: '10px 0px 0px 0px',
      // }
  },
  layout : {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
  },
  phoneInput:{
    margin : "12px 5px",
    width: "20rem",
    "& .MuiFormControl-root > .MuiInputBase-root > input":{
      padding:"11px 10px"
    }
  }
}));

export default useStyles;
