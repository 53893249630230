import * as yup from "yup";

export const schemeValidation = yup.object({
  facility: yup.string().required("Facility is required"),
  year: yup.number().required("Year is required"),
  tracking: yup.string().required("tracking period is required"),
  select_initiative: yup.string().required("Intiative is required"),
  duration: yup.number().required("Duration is required"),
});
export const schemeTaskValidation = yup.object({
  assessmentPeriod: yup.string().required("Task period is required"),
  task: yup.string().required("Task is required"),
});
