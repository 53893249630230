import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Paper, Typography } from "@mui/material";
import {
  getEmissionsByScopeByYear,
  getProductionEmissionGraphData,
  getTicketStatus,
} from "../../../redux/actions";
import useStyles from "./styles";
import StackedColumnChart from "./StackedColumnChart";
import { getCookie } from "../../../services/cookie";

const ProductionEmissionGraph = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const role = getCookie("role");
  const companyId = getCookie("companyId");
  const isAuditor = getCookie("role") === "auditor";
  

  const EmissionProductionData = useSelector(
    (state) => state.dashboard.getProductionEmissionGraphData.data
  );
  

  if (role === "auditor") {
    var userRole = "auditor";
  } else {
    userRole = "business";
  }

  useEffect(() => {
    console.log("nik");
    
    !props.refreshContent && dispatch(getProductionEmissionGraphData(props.filter, role, companyId));
    
  }, [dispatch, props.filter, userRole]);

  return (
    <Paper className={classes.container}>
      {role === "proxy_sustainability_manager" || role === "sustainability_manager" ? (
        <>
          <Typography className={classes.graphTitle}>
            Total Emission Per Unit of Produce ({EmissionProductionData?.unit?EmissionProductionData.unit:'Tonnes'})
          </Typography>
          {EmissionProductionData.status === true ? (
            EmissionProductionData.data?.length > 0 ? (
              <div>
                <StackedColumnChart
                  apiResponse={EmissionProductionData}
                  filter={props.filter}
                />
              </div>
            ) : EmissionProductionData.data?.length === 0 ? (
              <div style={{ minHeight: 250 }}>No Data Available</div>
            ) : (
              <div style={{ minHeight: 250 }}>
                Graph view only supports filtering data based on the year
              </div>
            )
          ) : (
            "Data loading ..."
          )}
        </>
      ) : ""}
    </Paper>
  );
};

export default ProductionEmissionGraph;
