import { Autocomplete, Box, Container, Divider, Grid, IconButton, TextField, Typography } from "@mui/material";

import useStyles from "./styles";
import CeroItemPair from "../../../../../components/CeroItemPair";
import EditOutlinedIcon from "@mui/icons-material/Edit";
import { useEffect, useState } from "react";
import CeroPopUp from "../../../../../components/CeroPopUp";
import CeroButton from "../../../../../components/CeroButton";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { getCookie } from "../../../../../services/cookie";
import { editTask, getTasksList, getTrackingCycleAssessmentPeriod, resetGoals } from "../../../../../redux/actions";
import { useSnackbar } from "notistack";
import { APIEndpoints, STATUS } from "../../../../../redux/constants";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CeroInput from "../../../../../components/CeroInput";
import CeroSelect from "../../../../../components/CeroSelect";
import { useFormik } from "formik";
import axios from "axios";
import { schemeValidation } from "../../schema";
import { sampleYear, yearRange } from "../../../../../constants";

const Status = ({ initiativeDetails, initiativeId, onShowDetailsChange }) => {
  const classes = useStyles();
  const dispatch = useDispatch()
  const [isEditPopup, setIsEditPopup] = useState(false);
  const [task, setTask] = useState("")
  const [selectedPeriod, setSelectedPeriod] = useState("")
  const [selectedYear,   setSelectedYear] = useState(undefined)
  const [taskId, setTaskId] = useState()
  const [show, setShow] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [tasks, setTasks] = useState([]);
  const { enqueueSnackbar } = useSnackbar()
  const readOnlyMode = (getCookie("read_only_mode") === "disable_edit")
  const editData = useSelector((state) => state.goalSettings.editTask);
  const taskList = useSelector((state) => state.goalSettings.getTasksList.data);

  const navigateBack = () => {
    onShowDetailsChange(false);
  };

  const handleCloseEdit= () => {
    setIsEditPopup(false);
  }

  const onEditTask = () => {
    dispatch(editTask(taskId, task, selectedPeriod, selectedYear))
    setIsEditPopup(false)
  }

  const handleVisionChange = (event) => {
    setTask(event.target.value);
  }

  const trackingCycleAssessmentData = useSelector(
    (state) => state.goalSettings.getTrackingCycleAssessmentPeriod.data
  );

  const assessmentOptions = Array.isArray(trackingCycleAssessmentData)
    ? trackingCycleAssessmentData.map((item) => ({
        key: item.period,
        value: item.period,
      }))
    : [];

    useEffect(() => {
      if(initiativeDetails?.tracking_cycle) {
      dispatch(getTrackingCycleAssessmentPeriod(initiativeDetails?.tracking_cycle))}
    }, [dispatch, initiativeDetails?.tracking_cycle]);

  const handleEditPopup = () => {
    const yearsOption = yearRange(initiativeDetails?.year, initiativeDetails?.duration)
    
    return(
      <>
        <CloseIcon onClick={handleCloseEdit} className={classes.closeIcon} />
        <Typography variant="subtitle1">Enter Task</Typography>
        <TextField
          id="outlined-basic"
          variant="outlined"
          fullWidth
          value={task}
          onChange={handleVisionChange}
          InputLabelProps={{ shrink: false }}
          disabled={readOnlyMode}
        />
        <Typography variant="subtitle1" style={{marginTop: 10}}>Year</Typography>
        <Autocomplete
          options={yearsOption}
          getOptionLabel={(option) => option.value}
          renderInput={(params) => <TextField {...params} variant="outlined" value={selectedYear}/>}
          onChange={(event, item) => setSelectedYear(item.key)}
          value={yearsOption.find((option) => option.key === selectedYear)}
        />
        <Typography variant="subtitle1" style={{marginTop: 10}}>Period</Typography>
        <Autocomplete
          options={assessmentOptions}
          getOptionLabel={(option) => option.value}
          renderInput={(params) => <TextField {...params} variant="outlined" value={selectedPeriod}/>}
          onChange={(event, item) => setSelectedPeriod(item.value)}
          value={assessmentOptions.find((option) => option.value === selectedPeriod)}
        />
        <div className={classes.buttonContainer}>
          <CeroButton
            className={classes.buttonPrimary}
            variant="contained"
            onClick={onEditTask}
            buttonText="Save"
          />
          <CeroButton
            className={classes.buttonSecondary}
            variant="outlined"
            onClick={handleCloseEdit}
            buttonText="Cancel"
          />
        </div>
        </>)
  };

  const handleEdit = (id, name, period, year) => {
    setIsEditPopup(true)
    setTaskId(id)
    setTask(name)
    setSelectedPeriod(period)
    setSelectedYear(year)
  }

  useEffect(() => {
    if (editData.status === STATUS.SUCCESS) {
      enqueueSnackbar("Task edited successfully", { variant: "success", autoHideDuration: 3000});
      dispatch(getTasksList(initiativeId))
      dispatch(resetGoals())
    } else if (editData.status === STATUS.ERROR) {
      enqueueSnackbar(editData.message.message || "We couldn't process your request. Please try again later.", { variant: "error", autoHideDuration: 3000 });
      dispatch(resetGoals());
    }
  }, [editData, dispatch, enqueueSnackbar, initiativeId]);

  const formik = useFormik({
    initialValues: {
      assessmentPeriod: "",
      task: "",
      year:undefined
    },
    validationSchema: schemeValidation,
    onSubmit: () => {},
  });

  const authToken = getCookie("auth_token_admin");

   const onSaveTask = async (event) => {
     // event.preventDefault();
     const requestData = {
       name: formik.values.task,
       period: formik.values.assessmentPeriod,
       initiative_id: initiativeDetails?.id,
       year: formik.values.year
     };
     try {
       const response = await axios.post(
         APIEndpoints.ADD_TASKS,
         requestData,
         {
           headers: {
             Authorization: `Bearer ${authToken}`,
           },
         }
       );
       enqueueSnackbar("Task added successfully", { variant: "success" });
      dispatch(getTasksList(initiativeId))
      dispatch(resetGoals())
       setTasks((tasks) => [
         ...tasks,
         {
           name: response.data.name,
           period: response.data.period,
         },
       ]);
         formik.setValues({
           task: "",
           assessmentPeriod: "",
         });
     } catch (error) {
       enqueueSnackbar(error?.message, { variant: "error" });
     }
   };

  useEffect(() => {
    return ()=>{
      setTasks([])
    }
  }, []);

  return (
    <Box>
      <div
        className={classes.backContainer}
        onClick={navigateBack}
      >
        <ArrowBackIosIcon />
        Back
      </div>
      <Container className={classes.container}>
        <CeroItemPair
          title="Mission:"
          value={initiativeDetails?.mission}
          classes={{
            container: classes.missionContainer,
            title: classes.infoTitle,
            value: classes.value,
          }}
        />
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <CeroItemPair
              title="Initiative:"
              value={initiativeDetails?.initiative}
              classes={{
                container: classes.infoContainer,
                title: classes.infoTitle,
                value: classes.value,
              }}
            />
            <CeroItemPair
              title="Initiative Id:"
              value={initiativeDetails?.initiative_id}
              classes={{
                container: classes.infoContainer,
                title: classes.infoTitle,
                value: classes.value,
              }}
            />
            <CeroItemPair
              title="Start Year:"
              value={initiativeDetails?.year}
              classes={{
                container: classes.infoContainer,
                title: classes.infoTitle,
                value: classes.value,
              }}
            />
            
          </Grid>
          <Grid item xs={6}>
            <CeroItemPair
              title="Country:"
              value={initiativeDetails?.country}
              classes={{
                container: classes.infoContainer,
                title: classes.infoTitle,
                value: classes.value,
              }}
            />
            <CeroItemPair
              title="Facility:"
              value={initiativeDetails?.facility_name}
              classes={{
                container: classes.infoContainer,
                title: classes.infoTitle,
                value: classes.value,
              }}
            />
            <CeroItemPair
              title="Tracking Cycle:"
              value={initiativeDetails?.tracking_cycle}
              classes={{
                container: classes.infoContainer,
                title: classes.infoTitle,
                value: classes.value,
              }}
              />
              {initiativeDetails?.duration && <CeroItemPair
              title="Duration:"
              value={initiativeDetails?.duration}
              classes={{
                container: classes.infoContainer,
                title: classes.infoTitle,
                value: classes.value,
              }}
              />}
          </Grid>
        </Grid>
      </Container>
      {taskList.length > 0 && (
        <Container className={classes.containerBottom}>
          <Box>
            {taskList.map((task, index) => (
              <Box>
                <Box className={classes.taskDetailList}>
                  <div>
                  <span className={classes.span}>Task {index + 1} - </span>{" "}
                  {task.name}</div>
                  {!readOnlyMode && <IconButton onClick={()=> handleEdit(task.id, task.name, task.period, task.year)}>
                  <EditOutlinedIcon className={classes.icon} />
                  </IconButton>}
                </Box>
                {index < taskList.length - 1 && <Divider />}
              </Box>
            ))}
          </Box>
        </Container>
      )}
      <CeroButton
            buttonText="+ Add task"
            className={classes.addTaskButton}
            onClick={() => {
              setShow(true);
              setIsDisabled(true);
            }}
            disabled={readOnlyMode}
          />
          {show && (
          <Box style={{ width: "100%" }}>
            <Box
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <Box className={classes.ceroInputContainer}>
                <CeroInput
                  required
                  id="task"
                  name="task"
                  label="Enter Task"
                  fullWidth
                  value={formik.values.task}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.task &&
                    formik.errors.task
                  }
                />
              </Box>
              <Box className={classes.ceroSelectContainer}>
              <CeroSelect
                      required
                      id="year"
                      name="year"
                      label="Start Year"
                      fullWidth
                      options={yearRange(initiativeDetails?.year,initiativeDetails?.duration)}
                      selectedValue={formik.values.year}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.year && formik.errors.year}
                      // classes={{ container: classes.inputFieldContainer }}
                    />
              </Box>
              <Box className={classes.ceroSelectContainer}>
                <CeroSelect
                  required
                  id="assessmentPeriod"
                  name="assessmentPeriod"
                  label="Period"
                  fullWidth
                  options={assessmentOptions}
                  selectedValue={formik.values.assessmentPeriod}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.assessmentPeriod &&
                    formik.errors.assessmentPeriod
                  }
                />
              </Box>
            </Box>
            <Box>
              <CeroButton
                buttonText="Save"
                className={classes.saveButton}
                onClick={() => {
                  onSaveTask();
                  setShow(false);
                }}
              />
              <CeroButton
                buttonText="Cancel"
                className={classes.cancelButton}
                onClick={() => {
                  setShow(false);
                  setIsDisabled(false);
                }}
              />
            </Box>
          </Box>
        )}
      <CeroPopUp
          primaryPopUp={{
            open: isEditPopup,
            onClose: { handleCloseEdit },
            content: handleEditPopup(),
            header: { title: "Edit Task" },
          }}
          classes={{ dialog: classes.dialog }}
        />
    </Box>
  );
};

export default Status;
