// import React from "react";
// import {
//   Box,
//   Typography,
//   IconButton,
//   LinearProgress,
// } from "@mui/material";
// import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
// import CloseIcon from "@mui/icons-material/Close";

// const CeroUploadedFile = () => {
//   return (
//     <Box
//       sx={{
//         display: "flex",
//         alignItems: "center",
//         backgroundColor: "#f5f5f5",
//         borderRadius: "8px",
//         padding: "12px",
//         gap: "16px",
//         boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
//         maxWidth: "400px",
//       }}
//     >
//       {/* File Icon */}
//       <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
//         <InsertDriveFileIcon
//           sx={{ fontSize: "36px", color: "#00000080" }} // Light gray file icon
//         />
//       </Box>

//       {/* File Information */}
//       <Box sx={{ flex: 1 }}>
//         <Typography sx={{ fontSize: "16px", fontWeight: "bold" }}>
//           Environmental_Policy.xlsx
//         </Typography>
//         <Typography sx={{ fontSize: "14px", color: "#888" }}>500 KB</Typography>
//         <Typography sx={{ fontSize: "12px", color: "#800000", mt: "4px" }}>
//           File Uploaded Successfully
//         </Typography>
//         <Box sx={{ mt: "8px" }}>
//           <LinearProgress
//             variant="determinate"
//             value={100}
//             sx={{
//               height: "6px",
//               borderRadius: "4px",
//               backgroundColor: "#f0c4c4", // Light red background
//               "& .MuiLinearProgress-bar": {
//                 backgroundColor: "#800000", // Dark red progress bar
//               },
//             }}
//           />
//         </Box>
//       </Box>

//       {/* Close Button */}
//       <IconButton
//         sx={{
//           padding: "4px",
//           color: "#00000080", // Light gray for icon
//         }}
//       >
//         <CloseIcon />
//       </IconButton>
//     </Box>
//   );
// };

// export default CeroUploadedFile;
import React from "react";
import { Box, Typography, IconButton, LinearProgress } from "@mui/material";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import CloseIcon from "@mui/icons-material/Close";

const CeroUploadedFile = ({ fileName, fileSize, onRemove }) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        backgroundColor: "#f5f5f5",
        borderRadius: "8px",
        padding: "8px",
        gap: "16px",
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
        maxWidth: "400px",
      }}
    >
      {/* File Icon */}
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
        <InsertDriveFileIcon sx={{ fontSize: "20px", color: "#00000080" }} />
      </Box>

      {/* File Information */}
      <Box sx={{ flex: 1 }}>
        <Typography sx={{ fontSize: "14px", fontWeight: "bold" }}>{fileName}</Typography>
        <Typography sx={{ fontSize: "12px", color: "#888" }}>{fileSize}</Typography>
        <Typography sx={{ fontSize: "10px", color: "#800000", mt: "2px" }}>
          File Uploaded Successfully
        </Typography>
        <Box sx={{ mt: "6px" }}>
          <LinearProgress
            variant="determinate"
            value={100}
            sx={{
              height: "4px",
              borderRadius: "3px",
              backgroundColor: "#f0c4c4",
              "& .MuiLinearProgress-bar": { backgroundColor: "#800000" },
            }}
          />
        </Box>
      </Box>

      {/* Close Button */}
      <IconButton
        sx={{ padding: "4px", color: "#00000080" }}
        onClick={onRemove}
      >
        <CloseIcon />
      </IconButton>
    </Box>
  );
};

export default CeroUploadedFile;
