import React, { useEffect, useState } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import useStyles from "./styles";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import ReactCountryFlag from "react-country-flag";
import DashboardLayout from "../../../../layouts/DashboardLayout";
import {
  getIotData,
  getOffsetDetails,
  refreshIotData,
  resetCarbonOffset,
} from "../../../../redux/actions";
import CeroButton from "../../../../components/CeroButton";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import DataTable from "./DataTable";
import { useSnackbar } from "notistack";
import { STATUS } from "../../../../redux/constants";
import CeroAutoComplete from "../../../../components/CeroAutoComplete";
import { sampleYear } from "../../../../constants";
import {
  getOffsetIotDataDetails,
  resetApproveOffsetStatus,
  resetReviewOffsetStatus,
  resetSubmitOffsetStatus,
  submitOffsetData,
} from "../../../../redux/actions/approval";
import CeroPopUp from "../../../../components/CeroPopUp";
import { reviewOffsetData } from "../../../../redux/actions/approval";
import { approveOffsetData } from "../../../../redux/actions/approval";
import { getCookie } from "../../../../services/cookie";
import { rolesEnum } from "../../../../layouts/DashboardLayout/pages";
import PdfFileIconWithDownload from "../../../../assets/images/Download";
import { capitalizeFirstLetterOfEachWord } from "../../../../services/utilityService";
import CeroInfoPair from "../../../../components/CeroInfoPair";

const MonthlyProgramDetailsPending = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const [comment, setComment] = useState("");
  const [submitPopup, setSubmitPopup] = useState(false);
  const [reviewPopup, setReviewPopup] = useState(false);
  const [isApprove, setIsApprove] = useState(false);
  const [isApproved, setIsApproved] = useState(false);
  const location = useLocation();
  const { year, period } = location.state;

  const role = getCookie("role");

  const projectDetails = useSelector(
    (state) => state.carbonOffset.getOffsetDetails.data
  );

  const projectDetailsCard = useSelector(
    (state) => state.carbonOffset.getOffsetDetails.status
  );

  const iotDetailCard = useSelector(
    (state) => state.carbonOffset.getIotData.data
  );
  const iotDetails = useSelector(
    (state) => state.approval.getOffsetIotDataDetails.data
  );
  const updatedStatus = useSelector(
    (state) => state.carbonOffset.updateIotData
  );
  const approveData = useSelector((state) => state.approval.approveOffsetData);
  const reviewData = useSelector((state) => state.approval.reviewOffsetData);
  const submitData = useSelector((state) => state.approval.submitOffsetData);
  const yearList = sampleYear().map((item) => ({
    id: item.key,
    label: item.value,
  }));

  useEffect(() => {
    dispatch(getIotData(id, year));
    dispatch(getOffsetIotDataDetails(id, year, period));
  }, [dispatch, id, year, updatedStatus]);

  useEffect(() => {
    if (approveData?.status === STATUS.SUCCESS) {
      enqueueSnackbar("Data Approved successfully!", {
        variant: "success",
        autoHideDuration: 3000,
      });
      dispatch(getIotData(id, year));
      dispatch(resetApproveOffsetStatus());
      setIsApproved(true);
    } else if (approveData?.status === STATUS.ERROR) {
      enqueueSnackbar(
        approveData?.message?.message
          ? approveData?.message?.message
          : "We couldn't process your request. Please try again later.",
        { variant: "error", autoHideDuration: 3000 }
      );
      dispatch(resetApproveOffsetStatus());
    }
  }, [approveData, enqueueSnackbar, dispatch]);

  useEffect(() => {
    if (reviewData?.status === STATUS.SUCCESS) {
      enqueueSnackbar("Program sent for Review and a ticket is created !", {
        variant: "success",
        autoHideDuration: 3000,
      });
      dispatch(getIotData(id, year));
      dispatch(resetReviewOffsetStatus());
      navigate("/offset-approval-pending");
    } else if (reviewData?.status === STATUS.ERROR) {
      enqueueSnackbar(
        reviewData?.message?.message
          ? reviewData?.message?.message
          : "We couldn't process your request. Please try again later.",
        { variant: "error", autoHideDuration: 3000 }
      );
      dispatch(resetReviewOffsetStatus());
    }
  }, [reviewData, enqueueSnackbar, dispatch]);

  useEffect(() => {
    if (submitData?.status === STATUS.SUCCESS) {
      enqueueSnackbar("Data Submitted successfully!", {
        variant: "success",
        autoHideDuration: 3000,
      });
      dispatch(resetSubmitOffsetStatus());
      navigate("/offset-approval-pending");
    } else if (submitData?.status === STATUS.ERROR) {
      enqueueSnackbar(
        submitData?.message?.message
          ? submitData?.message?.message
          : "We couldn't process your request. Please try again later.",
        { variant: "error", autoHideDuration: 3000 }
      );
      dispatch(resetSubmitOffsetStatus());
    }
  }, [submitData, enqueueSnackbar, dispatch]);

  useEffect(() => {
    id && dispatch(getOffsetDetails(id));
  }, [dispatch, id]);

  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  const handleReviewPopup = (item) => {
    setReviewPopup(true);
    setIsApprove(false);
    setSubmitPopup(false);
  };

  const handleApprovePopup = (item) => {
    const data = {};
    dispatch(approveOffsetData(id, year, period, data));
  };

  const onSubmitReview = () => {
    const data = {
      topics: ["1234344"],
      comment: comment,
    };

    dispatch(reviewOffsetData(id, year, period, data));
    setReviewPopup(false);
    setComment("");
  };

  const handleSubmitDirectly = () => {
    const data = {
      topics: ["1234344"],
      comment: "",
    };

    dispatch(submitOffsetData(id, year, period, data));
  };

  const getStatus = (status) => {
    if (status === "approved") {
      return <Typography className={classes.approved}>Approved</Typography>;
    } else if (status === "assigned") {
      return (
        <Typography className={classes.assigned}>Pending Approval</Typography>
      );
    } else if (status === "audited") {
      return <Typography className={classes.audited}>Audited</Typography>;
    } else if (status === "review") {
      return <Typography className={classes.review}>Review</Typography>;
    } else if (status === "reported") {
      return <Typography className={classes.reported}>Reported</Typography>;
    } else if (status === "submitted") {
      return (
        <Typography className={classes.submitted}>Pending Approval</Typography>
      );
    } else if (status === "added") {
      return <Typography className={classes.added}>Added</Typography>;
    } else if (status === "verified") {
      return <Typography className={classes.verified}>Verified</Typography>;
    } else if (status === "In Progress") {
      return <Typography className={classes.submitted}>In Progress</Typography>;
    } else if (status === "pending approval") {
      return (
        <Typography className={classes.submitted}>Pending Approval</Typography>
      );
    } else {
      return <Typography className={classes.default}>{status}</Typography>;
    }
  };

  const getReviewComments = () => {
    return (
      <>
        <TextField
          id="outlined-multiline-static"
          multiline
          rows={5}
          placeholder="Comment"
          style={{ width: "100%" }}
          value={comment}
          onChange={handleCommentChange}
          className={classes.textField}
        />
        <div className={classes.textLimit}>
          <div className={classes.noComments}>
            {comment.trim().length === 0 ? "No comments" : ""}
          </div>
          <div className={classes.maxCharacters}>
            {"Maximum 200 characters"}
          </div>
        </div>
        <div className={classes.reviewButtons}>
          <CeroButton
            className={classes.buttonPrimary}
            variant="contained"
            onClick={onSubmitReview}
            buttonText="Save"
          />
          <CeroButton
            className={classes.buttonSecondary}
            variant="outlined"
            onClick={() => setReviewPopup(false)}
            buttonText="Cancel"
          />
        </div>
      </>
    );
  };

  return (
    <DashboardLayout>
      <div
        className={classes.backContainer}
        onClick={() => {
          navigate(-1);
        }}
      >
        <ArrowBackIosIcon />
        Back
      </div>
      {projectDetailsCard === STATUS.RUNNING ? (
        <Box className={classes.loader}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Box className={classes.boxContainer}>
            <Grid className={classes.titleGrid}>
              <Grid item>
                <Typography variant="h6">{projectDetails?.name}</Typography>
                <div className={classes.location}>
                  <ReactCountryFlag
                    countryCode={projectDetails?.country_code}
                    svg
                    style={{
                      width: "2em",
                      height: "1.8em",
                      marginRight: "0.8em",
                    }}
                  />
                  <Typography variant="subtitle1">
                    {projectDetails?.location}
                  </Typography>
                </div>
              </Grid>
              {role !== rolesEnum.BUSINESS_USER && (
                <Grid item display="flex">
                  {isApproved === false ? (
                    <>
                      <CeroButton
                        buttonText="Approve"
                        onClick={handleApprovePopup}
                        className={classes.buttonPrimary}
                      />
                      <CeroButton
                        buttonText="Review"
                        onClick={handleReviewPopup}
                        className={classes.buttonPrimary}
                      />
                    </>
                  ) : (
                    <>
                      <Typography>Status :&nbsp;</Typography>
                      <Typography className={classes.approved}>
                        Approved
                      </Typography>
                    </>
                  )}
                </Grid>
              )}

              {role === rolesEnum.BUSINESS_USER && (
                <Grid item display="flex">
                  {iotDetails?.status === "added" ? (
                    <CeroButton
                      buttonText="Submit"
                      onClick={handleSubmitDirectly}
                      className={classes.buttonPrimary}
                    />
                  ) : (
                    <Grid item display="flex">
                      <Typography>Status : &nbsp;</Typography>
                      <Typography
                        className={`${classes.infoContainer} ${
                          iotDetails?.status === "In Progress"
                            ? classes.Inprogress
                            : classes[iotDetails?.status]
                        }`}
                      >
                        {capitalizeFirstLetterOfEachWord(iotDetails?.status)}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              )}
            </Grid>
            <Typography className={classes.contentBox}>
              {projectDetails?.description}
            </Typography>
            <Grid spacing={5} className={classes.gridContainer}>
              <Grid item xs={4}>
                <Typography className={classes.gridItemTitle}>
                  Program Type
                </Typography>
                <Typography className={classes.gridItem}>
                  {projectDetails?.program_type}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography className={classes.gridItemTitle}>
                  Program
                </Typography>
                <Typography className={classes.gridItem}>
                  {projectDetails?.program_name}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography className={classes.gridItemTitle}>
                  Start year
                </Typography>
                <Typography className={classes.gridItem}>
                  {projectDetails?.start_year}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography className={classes.gridItemTitle}>
                  Installed Capacity (kW)
                </Typography>
                <Typography className={classes.gridItem}>
                  {
                    projectDetails?.program_specific_data?.find(
                      (data) => data.data_name === "Installed capacity (KW)"
                    )?.value
                  }
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography className={classes.gridItemTitle}>
                  No. of Panels installed
                </Typography>
                <Typography className={classes.gridItem}>
                  {
                    projectDetails?.program_specific_data?.find(
                      (data) => data.data_name === "number of panels"
                    )?.value
                  }
                </Typography>
              </Grid>
            </Grid>
          </Box>
          {projectDetails?.attachment_url?.length > 0 && (
            <Box
              display="flex"
              justifyContent="flex-end"
              paddingRight={5.5}
              gap={2}
              alignItems="center"
            >
              <Typography className={classes.attachmentTitle}>
                Attached Documents
              </Typography>
              {projectDetails?.attachment_url?.map((url) => (
                <a href={url} target="_blank" rel="noopener noreferrer">
                  <PdfFileIconWithDownload />
                </a>
              ))}
            </Box>
          )}
          <div className={classes.tableTitle}>
            <Typography variant="h6" paddingBottom={8} paddingTop={3}>
              Data collected from IoT Device
            </Typography>
            <DataTable
              programId={id}
              iotDetails={iotDetails}
              updatedStatus={updatedStatus}
            />
          </div>
        </>
      )}
      <CeroPopUp
        primaryPopUp={{
          open: reviewPopup,
          onClose: () => setReviewPopup(false),
          content: getReviewComments(),
          header: { title: "Comments" },
        }}
      />
      <CeroPopUp
        primaryPopUp={{
          open: submitPopup,
          onClose: () => setSubmitPopup(false),
          content: getReviewComments(),
          header: { title: "Comments" },
        }}
      />
    </DashboardLayout>
  );
};

export default MonthlyProgramDetailsPending;
