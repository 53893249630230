import { all, put, call, takeLatest } from 'redux-saga/effects'

import { request } from '../../services/client'
import { ActionTypes } from '../constants/actions';
import { APIEndpoints } from '../constants';

export function* getSupplierQuestions(action) {
    try {
        const { industryId } = action.payload
        const response = yield call(request, APIEndpoints.GET_SUPPLIER_QUESTIONS(industryId), {
            method: 'GET',
        })
        yield put({
            type: ActionTypes.GET_SUPPLIER_QUESTIONS_SUCCESS,
            payload: response.supplier_industry_questions
        })
    } catch (err) {
        /* istanbul ignore next */
        yield put({
            type: ActionTypes.GET_SUPPLIER_QUESTIONS_FAILURE,
            payload: err.message
        })
    }
}

export function* createSupplierQuestions(action) {
    try {        
        const { industryId, requestData } = action.payload
        const response = yield call(request, APIEndpoints.CREATE_SUPPLIER_QUESTIONS(industryId), {
            method: 'POST',
            payload: requestData
        })
        yield put({
            type: ActionTypes.CREATE_SUPPLIER_QUESTIONS_SUCCESS,
            payload: response
        })
    } catch (err) {
        /* istanbul ignore next */
        yield put({
            type: ActionTypes.SCREATE_SUPPLIER_QUESTIONS_FAILURE,
            payload: err.message
        })
    }
}

export function* getGenericQuestions() {
    try {
        const response = yield call(request, APIEndpoints.GET_GENERIC_QUESTIONS, {
            method: 'GET',
        })
        yield put({
            type: ActionTypes.GET_GENERIC_QUESTIONS_SUCCESS,
            payload: response.supplier_generic_questions
        })
    } catch (err) {
        /* istanbul ignore next */
        yield put({
            type: ActionTypes.GET_GENERIC_QUESTIONS_FAILURE,
            payload: err.message
        })
    }
}

export function* sendSupplierQuestions(action) {
    try {        
        const { senderList } = action.payload
        const response = yield call(request, APIEndpoints.SEND_SUPPLIER_QUESTIONS, {
            method: 'POST',
            payload: senderList
        })
        yield put({
            type: ActionTypes.SEND_SUPPLIER_QUESTIONS_SUCCESS,
            payload: response
        })
    } catch (err) {
        /* istanbul ignore next */
        yield put({
            type: ActionTypes.SEND_SUPPLIER_QUESTIONS_FAILURE,
            payload: err.message
        })
    }
}

export function* getSupplierSurveyQuestions(action) {
    try {
        const { answerId } = action.payload
        const response = yield call(request, APIEndpoints.GET_SUPPLIER_SURVEY_QUESTIONS(answerId), {
            method: 'GET',
        })
        yield put({
            type: ActionTypes.GET_SUPPLIER_SURVEY_QUESTIONS_SUCCESS,
            payload: response.supplier_generic_list
        })
    } catch (err) {
        /* istanbul ignore next */
        yield put({
            type: ActionTypes.GET_SUPPLIER_SURVEY_QUESTIONS_FAILURE,
            payload: err.message
        })
    }
}

export function* supplierSurveyResponse(action) {
    try {
        const { answerId, requestData } = action.payload
        const response = yield call(request, APIEndpoints.SUPPLIER_SURVEY_RESPONSE(answerId), {
            method: 'POST',
            payload: requestData
        })
        yield put({
            type: ActionTypes.SUPPLIER_SURVEY_RESPONSE_SUCCESS,
            payload: response
        })
    } catch (err) {
        /* istanbul ignore next */
        yield put({
            type: ActionTypes.SUPPLIER_SURVEY_RESPONSE_FAILURE,
            payload: err.message
        })
    }
}

export function* getSupplierSurveys(action) {
    const { year } = action.payload;
    try {
        const response = yield call(request, APIEndpoints.GET_SUPPLIER_SURVEYS(year), {
            method: 'GET',
        })
        yield put({
            type: ActionTypes.GET_SUPPLIER_SURVEYS_SUCCESS,
            payload: response
        })
    } catch (err) {
        /* istanbul ignore next */
        yield put({
            type: ActionTypes.GET_SUPPLIER_SURVEYS_FAILURE,
            payload: err.message
        })
    }
}

export function* getSupplierRespondentList(action) {
    try {
        const { id } = action.payload
        const response = yield call(request, APIEndpoints.GET_SUPPLIER_RESPONDENT_LIST(id), {
            method: 'GET',
        })
        yield put({
            type: ActionTypes.GET_SUPPLIER_RESPONDENT_LIST_SUCCESS,
            payload: response
        })
    } catch (err) {
        /* istanbul ignore next */
        yield put({
            type: ActionTypes.GET_SUPPLIER_RESPONDENT_LIST_FAILURE,
            payload: err.message
        })
    }
}

export function* sendRespondentReminder(action) {
    try {
        console.log('action.payload', action.payload)
        const { id, requestData } = action.payload
        const response = yield call(request, APIEndpoints.SEND_RESPONDENT_REMINDER(id), {
            method: 'POST',
            payload: {}
        })
        yield put({
            type: ActionTypes.SEND_RESPONDENT_REMINDER_SUCCESS,
            payload: response
        })
    } catch (err) {
        /* istanbul ignore next */
        yield put({
            type: ActionTypes.SEND_RESPONDENT_REMINDER_FAILURE,
            payload: err.message
        })
    }
}

export function* getSupplierSurveyTrackQuestions(action) {
    try {
        const { id } = action.payload
        const response = yield call(request, APIEndpoints.GET_SUPPLIER_SURVEY_TRACK_QUESTIONS(id), {
            method: 'GET',
        })
        yield put({
            type: ActionTypes.GET_SUPPLIER_SURVEY_TRACK_QUESTIONS_SUCCESS,
            payload: response
        })
    } catch (err) {
        /* istanbul ignore next */
        yield put({
            type: ActionTypes.GET_SUPPLIER_SURVEY_TRACK_QUESTIONS_FAILURE,
            payload: err.message
        })
    }
}

export function* getSupplierSurveyTrackAnswers(action) {
    try {
        const { id } = action.payload
        const response = yield call(request, APIEndpoints.GET_SUPPLIER_SURVEY_TRACK_ANSWERS(id), {
            method: 'GET',
        })
        yield put({
            type: ActionTypes.GET_SUPPLIER_SURVEY_TRACK_ANSWERS_SUCCESS,
            payload: response
        })
    } catch (err) {
        /* istanbul ignore next */
        yield put({
            type: ActionTypes.GET_SUPPLIER_SURVEY_TRACK_ANSWERS_FAILURE,
            payload: err.message
        })
    }
}
export function* getSupplierSurveyResults(action) {
    const { year, industry } = action.payload;
    try {
        const response = yield call(request, APIEndpoints.GET_SUPPLIER_SURVEY_RESULTS(year, industry), {
            method: 'GET',
        })
        yield put({
            type: ActionTypes.GET_SUPPLIER_SURVEY_RESULTS_SUCCESS,
            payload: response.survey_list
        })
    } catch (err) {
        /* istanbul ignore next */
        yield put({
            type: ActionTypes.GET_SUPPLIER_SURVEY_RESULTS_FAILURE,
            payload: err.message
        })
    }
}
export default function* root() {
    yield all([
        takeLatest(ActionTypes.GET_SUPPLIER_QUESTIONS, getSupplierQuestions),
        takeLatest(ActionTypes.CREATE_SUPPLIER_QUESTIONS, createSupplierQuestions),
        takeLatest(ActionTypes.GET_GENERIC_QUESTIONS, getGenericQuestions),
        takeLatest(ActionTypes.SEND_SUPPLIER_QUESTIONS, sendSupplierQuestions),
        takeLatest(ActionTypes.GET_SUPPLIER_SURVEY_QUESTIONS, getSupplierSurveyQuestions),
        takeLatest(ActionTypes.SUPPLIER_SURVEY_RESPONSE, supplierSurveyResponse),
        takeLatest(ActionTypes.GET_SUPPLIER_SURVEYS, getSupplierSurveys),
        takeLatest(ActionTypes.GET_SUPPLIER_RESPONDENT_LIST, getSupplierRespondentList),
        takeLatest(ActionTypes.SEND_RESPONDENT_REMINDER, sendRespondentReminder),
        takeLatest(ActionTypes.GET_SUPPLIER_SURVEY_TRACK_QUESTIONS, getSupplierSurveyTrackQuestions),
        takeLatest(ActionTypes.GET_SUPPLIER_SURVEY_TRACK_ANSWERS, getSupplierSurveyTrackAnswers),
        takeLatest(ActionTypes.GET_SUPPLIER_SURVEY_RESULTS, getSupplierSurveyResults),

    ])
}
