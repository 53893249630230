import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import DashboardLayout from "../../../../layouts/DashboardLayout";
import CeroButton from "../../../../components/CeroButton";
import useStyles from "./styles";
import { companyFunctionalitySetup, getSuperAdminOrganizationCountry } from "../../../../redux/actions";
import {
  getFunctionalityData,
  resetCompanyFunctionalitySetup,
} from "../../../../redux/actions/company";
import { useSnackbar } from "notistack";
import { STATUS } from "../../../../redux/constants";

const FunctionalitySetup = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { company, companyId } = location.state || {}; 

  const { enqueueSnackbar } = useSnackbar();

  const [selectedModules, setSelectedModules] = useState([]);
  const [isEditMode, setIsEditMode] = useState(true);

  useEffect(() => {
    if (companyId) {
      dispatch(getFunctionalityData(companyId));
      dispatch(getSuperAdminOrganizationCountry(companyId))
    }
  }, [dispatch, companyId]);

  const countryList = useSelector(
    (state) =>
      state?.superAdmin?.getOrganizationCountry?.data
        ?.country_code_corresponding_name 
  );

  const existingData = useSelector(
    (state) => state?.company?.getFunctionalityData?.data 
  );

  const companyFunctionalitySetupStatus = useSelector(
    (state) => state?.company?.companyFunctionalitySetup?.status 
  );

  const options = [
    "ESG Module",
    "Materiality",
    "Supplier Assessment",
    "Offset Reporting",
    "Carbon Credit Pool",
  ];

  useEffect(() => {
    if (existingData && Object.keys(existingData).length > 0) {
      const preSelectedModules = Object.entries(existingData).map(
        ([country, modules]) => ({
          country_code: Object.keys(countryList).find(
            (code) => countryList[code] === country
          ),
          country,
          modules,
        })
      );
      setSelectedModules(preSelectedModules);
      setIsEditMode(false);
    } else {
      setIsEditMode(true);
    }
  }, [existingData]);

  useEffect(() => {
    if (companyFunctionalitySetupStatus === STATUS.SUCCESS) {
      enqueueSnackbar("Functionality Setup saved successfully !", {
        variant: "success",
      });
      dispatch(resetCompanyFunctionalitySetup());
    } else if (companyFunctionalitySetupStatus === STATUS.ERROR) {
      enqueueSnackbar(
        "We couldn't process your request. Please try again later.",
        { variant: "error" }
      );
      dispatch(resetCompanyFunctionalitySetup());
    }
  }, [companyFunctionalitySetupStatus, enqueueSnackbar, dispatch]);

  const handleCheckboxChange = (countryCode, countryName, option) => {
    setSelectedModules((prev) => {
      const countryEntry = prev.find(
        (item) => item.country_code === countryCode
      );

      if (countryEntry) {
        const updatedModules = countryEntry.modules.includes(option)
          ? countryEntry.modules.filter((module) => module !== option)
          : [...countryEntry.modules, option];

        if (updatedModules.length === 0) {
          return prev.filter((item) => item.country_code !== countryCode);
        }

        return prev.map((item) =>
          item.country_code === countryCode
            ? { ...item, modules: updatedModules }
            : item
        );
      }

      return [
        ...prev,
        { country_code: countryCode, country: countryName, modules: [option] },
      ];
    });
  };


  const handleSave = () => {
    if (selectedModules.length === 0) {
      enqueueSnackbar("Please select at least one functionality before saving.", {
        variant: "error",
      });
      return;
    }
  
    const payload = {
      country_module_mappings: selectedModules,
    };
    if (companyId) {
      dispatch(companyFunctionalitySetup(companyId, payload));
    }
    setIsEditMode(!isEditMode);
  };
  

  return (
    <DashboardLayout>
      <div
        className={classes.backContainer}
        onClick={() => navigate(-1)}
      >
        <ArrowBackIosIcon />
        Back
      </div>
      <Container className={classes.container}>
        <Paper sx={{ paddingBottom: 3, marginBottom: 5 }}>
          <div className={classes.topContainer}>
            <Typography variant="h6" paddingLeft={10} paddingTop={10}>
              Functionality Setup for {company}
            </Typography>
            <CeroButton
              buttonText={!isEditMode ? "Edit" : "Save"}
              className={classes.buttonPrimary}
              onClick={() => {
                if (isEditMode) handleSave();
                else setIsEditMode(true);
              }}
            />
          </div>
          {countryList &&
            Object.entries(countryList).map(([countryCode, countryName]) => (
              <Grid
                key={countryCode}
                container
                spacing={2}
                className={classes.box}
              >
                <Typography variant="h6">{countryName}</Typography>
                <div className={classes.optionContainer}>
                  {options.map((option, optionIndex) => {
                    const isChecked = selectedModules.some(
                      (item) =>
                        item?.country_code === countryCode &&
                        item?.modules?.includes(option)
                    );

                    return (
                      <FormControlLabel
                        key={`${countryCode}-${optionIndex}`}
                        control={
                          <Checkbox
                            size="small"
                            checked={isChecked}
                            onChange={() =>
                              handleCheckboxChange(
                                countryCode,
                                countryName,
                                option
                              )
                            }
                            disabled={!isEditMode}
                            sx={{
                              color: "#8b2147",
                              paddingRight: 1,
                              "&.Mui-checked": {
                                color: "#8b2147",
                              },
                            }}
                          />
                        }
                        label={option}
                        sx={{
                          "& .MuiFormControlLabel-label": {
                            fontWeight: "420",
                          },
                        }}
                      />
                    );
                  })}
                </div>
              </Grid>
            ))}
        </Paper>
      </Container>
    </DashboardLayout>
  );
};

export default FunctionalitySetup;
