import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  backContainer: {
    color: theme.palette.Primary.background,
    textTransform: "capitalize",
    display: "flex",
    padding: theme.spacing(4,0),
    alignItems: "center",
    cursor: "pointer",
    marginLeft: theme.spacing(5),
  },
  boxContainer: {
    backgroundColor: "white",
    borderRadius: 5,
    margin: theme.spacing(3,5,5,5),
    padding: theme.spacing(5,0),
    boxShadow:
      "0px 4px 4px rgba(0, 0, 0, 0.05), 0px -4px 4px rgba(0, 0, 0, 0.05), 4px 0px 4px rgba(0, 0, 0, 0.05), -4px 0px 4px rgba(0, 0, 0, 0.05)",
  },
  gridContainer: {
    borderTop: "1px solid #5A404E",
    borderBottom: "1px solid #5A404E",
    padding: theme.spacing(3, 0),
    margin: theme.spacing(5, 0),
    display: "flex",
    justifyContent: "space-evenly",
    textAlign: "center",
  },
  gridItemTitle: {
    fontSize: 14,
  },
  gridItem: {
    fontWeight: 600,
    fontSize: 16,
    paddingTop: theme.spacing(2),
  },
  titleGrid: {
    display: "flex",
    justifyContent: "space-between",
    padding: theme.spacing(5),
  },
  contentBox: {
    padding: theme.spacing(0,5),
  },
  contactBox: {
    padding: theme.spacing(3, 5),
  },
  location: {
    display: "flex",
    marginTop: theme.spacing(2),
    alignItems: "flex-end"
  },
  topRight: {
    color: "#61432D",
    fontWeight: 500,
    paddingRight: theme.spacing(8),
  },
  title: {
    marginBottom: theme.spacing(2),
    fontSize: "20px",
    fontWeight: 500,
  },
  buttonPrimary: {
    background: theme.palette.Primary.background,
    textTransform: "capitalize",
    width: 140,
    height: 42,
    marginRight: 15,
    fontWeight: 400,
    "&:hover": {
      backgroundColor: theme.palette.Primary.hoverButton,
      color: theme.palette.Primary.light,
    },
  },
  buttonSecondary: {
    color: theme.palette.Primary.background,
    borderColor: theme.palette.Primary.background,
    textTransform: "capitalize",
    width: 120,
    height: 42,
    "&:hover": {
      borderColor: theme.palette.Primary.background,
      color: theme.palette.Secondary.hoverButton,
      backgroundColor: theme.palette.Primary.background,
    },
    marginRight: theme.spacing(5),
  },
  textField: {
    width: "28%",
    "& label.Mui-focused": {
      color: theme.palette.Primary.main,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: theme.palette.Primary.main,
    },
    "& .MuiInput-root:hover::before": {
      borderWidth: 1,
      borderBottomColor: theme.palette.Primary.main,
    },
    "& .MuiFilledInput-root:hover::before": {
      borderBottomColor: theme.palette.Primary.main,
    },
    "& .MuiFilledInput-underline:after": {
      borderColor: theme.palette.Primary.main,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: theme.palette.Primary.main,
        // backgroundColor: theme.palette.background.white,
      },
      "&:hover fieldset": {
        borderColor: theme.palette.Primary.main,
      },
      "&.Mui-focused fieldset": {
        borderWidth: 2,
        borderColor: theme.palette.Primary.main,
      },
    },
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& .css-czrb7a-MuiFormLabel-root-MuiInputLabel-root,.css-kyi7ki-MuiFormLabel-root-MuiInputLabel-root.Mui-focused":
      {
        color: theme.palette.Primary.main,
      },
  },
  filter:{
    width: "20%",
    backgroundColor: "white",
  },
  tableTitle:{
    margin: theme.spacing(0, 5),
  },
  loader: {
    width: "100%",
    height: 100,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "red",
  },
  infoContainer: {
    maxWidth: 400,
    display:"flex !important",
    justifyContent: 'flex-start',
    "& .MuiTypography-root": {
      minWidth: 140,
    },
    "& .MuiBox-root > .MuiTypography-root": {
      paddingBottom: 4,
    },
  },
  approved: {
    color: theme.palette.status.approved,
    fontWeight:500,
    fontSize: 14
  },
  assigned: {
    color: theme.palette.status.pendingApproval,
    fontWeight:500,
    fontSize: 14
  },
  audited: {
    color: theme.palette.status.audited,
    fontWeight:500,
    fontSize: 14
  },
  submitted:{
    color: theme.palette.status.pendingApproval,
    fontWeight:500,
    fontSize: 14
  },
  added:{
    color: theme.palette.status.added,
    fontWeight:500,
    fontSize: 14
  },
  Inprogress:{
    color: theme.palette.status.pendingApproval,
    fontWeight:500,
    fontSize: 14
  },
  verified:{
    color: theme.palette.status.verified,
    fontWeight:500,
    fontSize: 14
  },
  review:{
    color: theme.palette.status.review,
    fontWeight:500,
    fontSize: 14
  },
  pending_approval:{
    color: theme.palette.status.pendingApproval,
    fontWeight:500,
    fontSize: 14
  },
  statusContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginRight: "15%",
    marginLeft: "2%",
  },
  attachmentTitle:{
    fontWeight:550,
    paddingRight: '15px',
  }
}));

export default useStyles;
