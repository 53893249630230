import { handleActions } from "redux-actions";
import immutable from "immutability-helper";

import { ActionTypes } from "../constants/actions";
import { STATUS } from "../constants";
import { parseError } from "../../services/client";

export const supplierScreeningState = {
  getSupplierQuestions: {
    data: {},
    status: STATUS.IDLE,
    message: "",
  },
  createSupplierQuestions: {
    data: {},
    status: STATUS.IDLE,
    message: "",
  },
  getGenericQuestions: {
    data: {},
    status: STATUS.IDLE,
    message: "",
  },
  sendSupplierQuestions: {
    data: {},
    status: STATUS.IDLE,
    message: "",
  },
  getSupplierSurveyQuestions:{
    data: {},
    status: STATUS.IDLE,
    message: "",
  },
  supplierSurveyResponse:{
    data: {},
    status: STATUS.IDLE,
    message: "",
  },
  getSupplierSurveys:{
    data: {},
    status: STATUS.IDLE,
    message: "",
  },
  getSupplierRespondentList:{
    data: {},
    status: STATUS.IDLE,
    message: "",
  },
  sendRespondentReminder:{
    data: {},
    status: STATUS.IDLE,
    message: "",
  },
  getSupplierSurveyTrackQuestions:{
    data: {},
    status: STATUS.IDLE,
    message: "",
  },
  getSupplierSurveyTrackAnswers:{
    data: {},
    status: STATUS.IDLE,
    message: "",
  },
  getSupplierSurveyResults:{
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
};

const supplierScreeningActions = {
  supplierScreening: handleActions(
    {
      [ActionTypes.GET_SUPPLIER_QUESTIONS]: (state, { payload }) =>
        immutable(state, {
          getSupplierQuestions: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.GET_SUPPLIER_QUESTIONS_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          getSupplierQuestions: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.GET_SUPPLIER_QUESTIONS_FAILURE]: (state, { payload }) =>
        immutable(state, {
          getSupplierQuestions: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),

      [ActionTypes.CREATE_SUPPLIER_QUESTIONS]: (state, { payload }) =>
        immutable(state, {
          createSupplierQuestions: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.CREATE_SUPPLIER_QUESTIONS_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          createSupplierQuestions: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.CREATE_SUPPLIER_QUESTIONS_FAILURE]: (state, { payload }) =>
        immutable(state, {
          createSupplierQuestions: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),

      [ActionTypes.GET_GENERIC_QUESTIONS]: (state, { payload }) =>
        immutable(state, {
          getGenericQuestions: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.GET_GENERIC_QUESTIONS_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          getGenericQuestions: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.GET_GENERIC_QUESTIONS_FAILURE]: (state, { payload }) =>
        immutable(state, {
          getGenericQuestions: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),

      [ActionTypes.SEND_SUPPLIER_QUESTIONS]: (state, { payload }) =>
        immutable(state, {
          sendSupplierQuestions: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.SEND_SUPPLIER_QUESTIONS_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          sendSupplierQuestions: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.SEND_SUPPLIER_QUESTIONS_FAILURE]: (state, { payload }) =>
        immutable(state, {
          sendSupplierQuestions: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),

        [ActionTypes.GET_SUPPLIER_SURVEY_QUESTIONS]: (state, { payload }) =>
          immutable(state, {
            getSupplierSurveyQuestions: {
              status: { $set: STATUS.RUNNING },
            },
          }),
        [ActionTypes.GET_SUPPLIER_SURVEY_QUESTIONS_SUCCESS]: (state, { payload }) =>
          immutable(state, {
            getSupplierSurveyQuestions: {
              status: { $set: STATUS.SUCCESS },
              data: { $set: payload },
            },
          }),
        [ActionTypes.GET_SUPPLIER_SURVEY_QUESTIONS_FAILURE]: (state, { payload }) =>
          immutable(state, {
            getSupplierSurveyQuestions: {
              status: { $set: STATUS.ERROR },
              message: { $set: parseError(payload) },
            },
          }),

          [ActionTypes.SUPPLIER_SURVEY_RESPONSE]: (state, { payload }) =>
            immutable(state, {
              supplierSurveyResponse: {
                status: { $set: STATUS.RUNNING },
              },
            }),
          [ActionTypes.SUPPLIER_SURVEY_RESPONSE_SUCCESS]: (state, { payload }) =>
            immutable(state, {
              supplierSurveyResponse: {
                status: { $set: STATUS.SUCCESS },
                data: { $set: payload },
              },
            }),
          [ActionTypes.SUPPLIER_SURVEY_RESPONSE_FAILURE]: (state, { payload }) =>
            immutable(state, {
              supplierSurveyResponse: {
                status: { $set: STATUS.ERROR },
                message: { $set: parseError(payload) },
              },
            }),
          [ActionTypes.GET_SUPPLIER_SURVEYS]: (state, { payload }) =>
            immutable(state, {
              getSupplierSurveys: {
                status: { $set: STATUS.RUNNING },
              },
            }),
          [ActionTypes.GET_SUPPLIER_SURVEYS_SUCCESS]: (state, { payload }) =>
            immutable(state, {
              getSupplierSurveys: {
                status: { $set: STATUS.SUCCESS },
                data: { $set: payload },
              },
            }),
          [ActionTypes.GET_SUPPLIER_SURVEYS_FAILURE]: (state, { payload }) =>
            immutable(state, {
              getSupplierSurveys: {
                status: { $set: STATUS.ERROR },
                message: { $set: parseError(payload) },
              },
            }),
            [ActionTypes.GET_SUPPLIER_RESPONDENT_LIST]: (state, { payload }) =>
              immutable(state, {
                getSupplierRespondentList: {
                  status: { $set: STATUS.RUNNING },
                },
              }),
            [ActionTypes.GET_SUPPLIER_RESPONDENT_LIST_SUCCESS]: (state, { payload }) =>
              immutable(state, {
                getSupplierRespondentList: {
                  status: { $set: STATUS.SUCCESS },
                  data: { $set: payload },
                },
              }),
            [ActionTypes.GET_SUPPLIER_RESPONDENT_LIST_FAILURE]: (state, { payload }) =>
              immutable(state, {
                getSupplierRespondentList: {
                  status: { $set: STATUS.ERROR },
                  message: { $set: parseError(payload) },
                },
              }),
              [ActionTypes.SEND_RESPONDENT_REMINDER]: (state, { payload }) =>
                immutable(state, {
                  sendRespondentReminder: {
                    status: { $set: STATUS.RUNNING },
                  },
                }),
              [ActionTypes.SEND_RESPONDENT_REMINDER_SUCCESS]: (state, { payload }) =>
                immutable(state, {
                  sendRespondentReminder: {
                    status: { $set: STATUS.SUCCESS },
                    data: { $set: payload },
                  },
                }),
              [ActionTypes.SEND_RESPONDENT_REMINDER_FAILURE]: (state, { payload }) =>
                immutable(state, {
                  sendRespondentReminder: {
                    status: { $set: STATUS.ERROR },
                    message: { $set: parseError(payload) },
                  },
                }),
                [ActionTypes.RESET_SEND_RESPONDENT_REMINDER_STATUS]: (state, { payload }) =>
                  immutable(state, {
                    sendRespondentReminder: {
                          status: { $set: STATUS.IDLE },
                          message: { $set: '' }
                    },
                  }),
                [ActionTypes.GET_SUPPLIER_SURVEY_TRACK_QUESTIONS]: (state, { payload }) =>
                  immutable(state, {
                    getSupplierSurveyTrackQuestions: {
                      status: { $set: STATUS.RUNNING },
                    },
                  }),
                [ActionTypes.GET_SUPPLIER_SURVEY_TRACK_QUESTIONS_SUCCESS]: (state, { payload }) =>
                  immutable(state, {
                    getSupplierSurveyTrackQuestions: {
                      status: { $set: STATUS.SUCCESS },
                      data: { $set: payload },
                    },
                  }),
                [ActionTypes.GET_SUPPLIER_SURVEY_TRACK_QUESTIONS_FAILURE]: (state, { payload }) =>
                  immutable(state, {
                    getSupplierSurveyTrackQuestions: {
                      status: { $set: STATUS.ERROR },
                      message: { $set: parseError(payload) },
                    },
                  }),
                  [ActionTypes.GET_SUPPLIER_SURVEY_TRACK_ANSWERS]: (state, { payload }) =>
                    immutable(state, {
                      getSupplierSurveyTrackAnswers: {
                        status: { $set: STATUS.RUNNING },
                      },
                    }),
                  [ActionTypes.GET_SUPPLIER_SURVEY_TRACK_ANSWERS_SUCCESS]: (state, { payload }) =>
                    immutable(state, {
                      getSupplierSurveyTrackAnswers: {
                        status: { $set: STATUS.SUCCESS },
                        data: { $set: payload },
                      },
                    }),
                  [ActionTypes.GET_SUPPLIER_SURVEY_TRACK_ANSWERS_FAILURE]: (state, { payload }) =>
                    immutable(state, {
                      getSupplierSurveyTrackAnswers: {
                        status: { $set: STATUS.ERROR },
                        message: { $set: parseError(payload) },
                      },
                    }),

      [ActionTypes.RESET_SUPPLIER_SCREENING_STATUS]: (state, { payload }) =>
        immutable(state, {
          sendSupplierQuestions:{
            status: { $set: STATUS.IDLE },
            message: { $set: "" },
          },
          supplierSurveyResponse:{
            status: { $set: STATUS.IDLE },
            message: { $set: "" },
          }
        }),
      [ActionTypes.GET_SUPPLIER_SURVEY_RESULTS]: (state, { payload }) =>
        immutable(state, {
          getSupplierSurveyResults: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.GET_SUPPLIER_SURVEY_RESULTS_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          getSupplierSurveyResults: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.GET_SUPPLIER_SURVEY_RESULTS_FAILURE]: (state, { payload }) =>
        immutable(state, {
          getSupplierSurveyResults: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),
    },
    supplierScreeningState
  ),
};

export default supplierScreeningActions;
