import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Container, Grid, Paper, Typography } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import useStyles from "./styles";
import DashboardLayout from "../../../layouts/DashboardLayout";
import { functionalityView } from "../../../redux/actions";

const FunctionalityView = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const moduleList = useSelector(
    (state) => state.company.functionalityView.data.data
  );

  useEffect(() => {
    dispatch(functionalityView());
  }, [dispatch]);

  const getCountryName = (countryCode) => {
    try {
      return new Intl.DisplayNames(["en"], { type: "region" }).of(countryCode);
    } catch {
      return countryCode;
    }
  };

  return (
    <DashboardLayout>
      <div className={classes.backContainer} onClick={() => navigate(-1)}>
        <ArrowBackIosIcon />
        Back
      </div>
      <Container className={classes.container}>
        <Paper sx={{ paddingBottom: 3, marginBottom: 5 }}>
          <div className={classes.topContainer}>
            <Typography variant="h6" paddingLeft={10} paddingTop={10}>
              Functionality View
            </Typography>
          </div>
          {moduleList &&
            Object.keys(moduleList).map((module) => {
              const countries = moduleList[module];
              return (
                <Grid key={module} className={classes.box}>
                  <Typography variant="h6" gutterBottom>
                    {module}
                  </Typography>
                  <Grid container spacing={2}>
                    {countries.map((countryCode) => {
                      const countryName = getCountryName(countryCode);
                      return (
                        <Grid item key={countryCode} marginRight={35}>
                          <Typography
                            variant="body1"
                            display="flex"
                            alignItems="center"
                          >
                            <span
                              style={{
                                color: "#8b2147",
                                fontSize: "1.2rem",
                                marginRight: "6px",
                              }}
                            >
                              •
                            </span>
                            {countryName}
                          </Typography>
                        </Grid>
                      );
                    })}
                  </Grid>
                </Grid>
              );
            })}
        </Paper>
      </Container>
    </DashboardLayout>
  );
};

export default FunctionalityView;
