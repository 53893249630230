import { Autocomplete, Paper, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import clsx from "clsx";

import useStyles from "./styles";
import { isObject, isString } from "lodash";

const CeroAutoComplete = (props) => {
    const classes = useStyles();
    let options = props.options;
    const liProps =  props.itemMarginLeft
    
    if (props.options.length > 0 && isString(props.options[0])) {
        options = props.sortOption
            ? props.options.sort((a, b) => a.localeCompare(b))
            : props.options;
    } else if (props.options.length > 0 && isObject(props.options[0])) {
        if (props.options[0]["label"]) {
            options = props.sortOption
                ? props.options.sort((a, b) => a.label.localeCompare(b.label))
                : props.options;
        } else if (props.options[0]["value"]) {
            options = props.sortOption
                ? props.options.sort((a, b) => a.value.localeCompare(b.value))
                : props.options;
        }
    }

    const isOptionEqualToValue = props.isOptionEqualToValue || ((option, value) => option.id === value.id);

    const CustomPaper = (props) => {
        return <Paper {...props} className={classes.paper} />;
    };

    const boxStyles = clsx(
        classes.container,
        props.error && classes.errorBox
    );

    return (
        <Box className={boxStyles}>
            <Autocomplete
                disablePortal
                disabled={props.disabled}
                id={props.id}
                value={props.value}
                classes={{
                    root: clsx(
                        props.error ? props.classes?.selectError : '',
                        props.classes?.root
                    ),
                }}
                options={options}
                onChange={props.onChange}
                multiple={!!props.multiple}
                onBlur={props.onBlur}
                onInputChange={props.onInputChange}
                PaperComponent={CustomPaper}
                filterOptions={props.filterOptions || undefined}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        required={props.required}
                        name={props.name}
                        label={props.label}
                        onBlur={props.onBlur}
                        error={!!props.error}

                    />
                )}
                renderOption={(props, option) => {
                    return (
                    <Box 
                        component="li" 
                        {...props} 
                        style={{
                        textAlign: 'left',
                        marginLeft: liProps || '0px', // Set padding dynamically
                        }}
                    >
                        {option.label || option.value || option}
                    </Box>

                )}}
                isOptionEqualToValue={isOptionEqualToValue}
            />
            {!!props.error && (
                <Typography
                    className={clsx(classes.text, classes.error, props.classes?.error)}
                >
                    {props.error}
                </Typography>
            )}
        </Box>
    );
};

export default CeroAutoComplete;
