import React, { useEffect, useState } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import useStyles from "./styles";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Box, CircularProgress, Grid, Paper, Typography } from "@mui/material";
import ReactCountryFlag from "react-country-flag";
import DashboardLayout from "../../../layouts/DashboardLayout";
import {
  getCarbonOffsetFormDetails,
  getIotData,
  getOffsetDetails,
  refreshIotData,
  resetCarbonOffset,
} from "../../../redux/actions";
import CeroButton from "../../../components/CeroButton";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import DataTable from "./DataTable";
import { useSnackbar } from "notistack";
import { STATUS } from "../../../redux/constants";
import CeroAutoComplete from "../../../components/CeroAutoComplete";
import { sampleYear } from "../../../constants";
import PdfFileIconWithDownload from "../../../assets/images/Download";
import CreateCarbonOffsetPopup from "../CreateCarbonOffsetPopup";
import { getCookie } from "../../../services/cookie";
import { rolesEnum } from "../../../layouts/DashboardLayout/pages";

const CarbonOffsetDetails = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const dispatch = useDispatch();
  const role = getCookie("role");
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const windowLocation = useLocation();
  const { choosenYear = null, choosenPeriod = null } =
    windowLocation?.state || {};
  const [year, setYear] = useState(0);
  const [addProgramPopup, setAddProgramPopup] = useState(false);
  const [params] = useSearchParams();
  const action = params.get("action");
  const isEdit = action === "edit";

  const projectDetails = useSelector(
    (state) => state.carbonOffset.getOffsetDetails.data
  );

  const projectDetailsSpecific = useSelector(
    (state) => state.carbonOffset.getOffsetDetails.data.program_specific_data
  );

  console.log("projectDetailsSpecific", choosenYear, choosenPeriod);

  const projectDetailsCard = useSelector(
    (state) => state.carbonOffset.getOffsetDetails.status
  );

  const iotDetails = useSelector((state) => state.carbonOffset.getIotData.data);
  const editDetails = useSelector(
    (state) => state.carbonOffset.getCarbonOffsetFormDetails?.data
  );

  const monthMap = {
    JAN: "1",
    FEB: "2",
    MAR: "3",
    APR: "4",
    MAY: "5",
    JUN: "6",
    JUL: "7",
    AUG: "8",
    SEP: "9",
    OCT: "10",
    NOV: "11",
    DEC: "12",
  };
  const monthRanges = {
    "JAN-MAR": { start: 1, end: 3 },
    "APR-JUN": { start: 4, end: 6 },
    "JUL-SEP": { start: 7, end: 9 },
    "OCT-DEC": { start: 10, end: 12 },
  };

  const formedData = {
    ...iotDetails,
    iot_data: (() => {
      if (!iotDetails?.iot_data) return [];

      // Check if choosenPeriod is a single month or a range
      const isSingleMonth = choosenPeriod
        ? monthMap[choosenPeriod.toUpperCase()]
        : null;

      const isRange = choosenPeriod
        ? monthRanges[choosenPeriod.toUpperCase()]
        : null;

      return iotDetails.iot_data.filter((item) => {
        // Match the year if provided
        const matchesYear = choosenYear
          ? item.year === choosenYear.toString()
          : true;

        // Match the month or range
        const matchesPeriod = isSingleMonth
          ? parseInt(item.month, 10) === parseInt(isSingleMonth, 10)
          : isRange
          ? item.month >= isRange.start && item.month <= isRange.end
          : true;

        return matchesYear && matchesPeriod;
      });
    })(),
  };

  const formedDataEdit = {
    ...editDetails,
    iot_data: (() => {
      // Check if iot_data exists
      if (!editDetails?.iot_data) return [];
      const mappedPeriod = choosenPeriod
        ? monthMap[choosenPeriod.toUpperCase()]
        : null;

      // Filter based on choosenPeriod and choosenYear
      return editDetails.iot_data.filter((item) => {
        const matchesPeriod = choosenPeriod
          ? item.month === mappedPeriod
          : true;
        const matchesYear = choosenYear
          ? item.year === choosenYear.toString()
          : true;
        return matchesPeriod && matchesYear;
      });
    })(),
  };

  const formDetails = useSelector(
    (state) =>
      state.carbonOffset.getCarbonOffsetFormDetails?.data?.offset_program
  );

  const updatedStatus = useSelector(
    (state) => state.carbonOffset.updateIotData
  );
  const refreshedData = useSelector(
    (state) => state.carbonOffset.refreshIotData
  );
  const yearList = sampleYear().map((item) => ({
    id: item.key,
    label: item.value,
  }));

  useEffect(() => {
    if (action !== "edit") dispatch(getIotData(id, year));
  }, [dispatch, id, year, updatedStatus]);

  useEffect(() => {
    if (refreshedData?.status === STATUS.SUCCESS) {
      enqueueSnackbar("Data fetched from IoT successfully!", {
        variant: "success",
        autoHideDuration: 3000,
      });
      dispatch(getIotData(id, year));
      dispatch(resetCarbonOffset());
    } else if (refreshedData?.status === STATUS.ERROR) {
      enqueueSnackbar(
        refreshedData?.message
          ? refreshedData?.message
          : "We couldn't process your request. Please try again later.",
        { variant: "error", autoHideDuration: 3000 }
      );
      dispatch(resetCarbonOffset());
    }
  }, [refreshedData, enqueueSnackbar, dispatch]);

  useEffect(() => {
    if (id) {
      dispatch(getOffsetDetails(id));
      dispatch(getCarbonOffsetFormDetails(id));
      // dispatch(getCarbonOffsetFormDetails("67348db914214e34cf45731e"));
    }
  }, [dispatch, id]);

  const refreshData = () => {
    id && dispatch(refreshIotData(id));
  };
  const onChangeFilter = (selectedValue) => {
    setYear(selectedValue.id);
  };

  const handleEdit = () => {
    setAddProgramPopup(true);
  };

  return (
    <DashboardLayout>
      <div
        className={classes.backContainer}
        onClick={() => {
          navigate(-1);
        }}
      >
        <ArrowBackIosIcon />
        Back
      </div>
      {projectDetailsCard === STATUS.RUNNING ? (
        <Box className={classes.loader}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Box className={classes.boxContainer}>
            <Grid className={classes.titleGrid}>
              <Grid item>
                <Typography variant="h6">
                  {isEdit ? formDetails?.name : projectDetails?.name}
                </Typography>
                <div className={classes.location}>
                  <ReactCountryFlag
                    countryCode={
                      isEdit
                        ? formDetails?.country_code
                        : projectDetails?.country_code
                    }
                    svg
                    style={{
                      width: "2em",
                      height: "1.8em",
                      marginRight: "0.8em",
                    }}
                  />
                  <Typography variant="subtitle1">
                    {isEdit ? formDetails?.location : projectDetails?.location}
                  </Typography>
                </div>
              </Grid>
              <Grid item display="flex">
                {action === "edit" && role !== rolesEnum.AUDITOR && (
                  <CeroButton
                    buttonText="Edit"
                    className={classes.buttonPrimary}
                    onClick={handleEdit}
                  />
                )}

                {action !== "edit" && role !== rolesEnum.AUDITOR && (
                  <CeroButton
                    buttonText="Refresh"
                    variant="outlined"
                    endIcon={<AutorenewIcon />}
                    onClick={refreshData}
                    className={classes.buttonSecondary}
                  />
                )}
              </Grid>
            </Grid>
            <Typography className={classes.contentBox}>
              {isEdit ? formDetails?.description : projectDetails?.description}
            </Typography>
            <Grid spacing={5} className={classes.gridContainer}>
              <Grid item xs={4}>
                <Typography className={classes.gridItemTitle}>
                  Program Type
                </Typography>
                <Typography className={classes.gridItem}>
                  {isEdit
                    ? formDetails?.program_type
                    : projectDetails?.program_type}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography className={classes.gridItemTitle}>
                  Program
                </Typography>
                <Typography className={classes.gridItem}>
                  {isEdit
                    ? formDetails?.program_name
                    : projectDetails?.program_name}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography className={classes.gridItemTitle}>
                  Start year
                </Typography>
                <Typography className={classes.gridItem}>
                  {isEdit
                    ? formDetails?.start_year
                    : projectDetails?.start_year}
                </Typography>
              </Grid>
              {/* <Grid
                container
                spacing={2}
                xs={6}
                justifyContent="flex-start"
              >
                {projectDetailsSpecific?.length > 0 &&
                  projectDetailsSpecific.map((item, index) => (
                    <Grid
                      item
                      xs={12 / projectDetailsSpecific?.length}
                      key={index}
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <Typography className={classes.gridItemTitle}>
                        {item.data_name}
                      </Typography>
                      <Typography className={classes.gridItem}>
                        {isEdit
                          ? formDetails?.program_specific_data?.find(
                              (data) => data.data_name === item.data_name
                            )?.value
                          : item.value}
                      </Typography>
                    </Grid>
                  ))}
              </Grid> */}
              <Grid container spacing={2} xs={6} justifyContent="flex-start">
                {(isEdit
                  ? formDetails?.program_specific_data
                  : projectDetailsSpecific
                )?.map((item, index) => {
                  const value = isEdit
                    ? formDetails?.program_specific_data?.find(
                        (data) => data.data_name === item.data_name
                      )?.value
                    : item.value;

                  return (
                    <Grid
                      item
                      xs={
                        12 /
                        (isEdit
                          ? formDetails?.program_specific_data?.length
                          : projectDetailsSpecific?.length)
                      }
                      key={index}
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <Typography className={classes.gridItemTitle}>
                        {item.data_name}
                      </Typography>
                      <Typography className={classes.gridItem}>
                        {value || "N/A"}
                      </Typography>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Box>
          {(isEdit
            ? formDetails?.attachment_url
            : projectDetails?.attachment_url
          )?.length > 0 && (
            <Box
              display="flex"
              justifyContent="flex-end"
              paddingRight={5.5}
              gap={2}
              alignItems="center"
              marginBottom={-22}
              flexDirection="row"
            >
              <Typography className={classes.attachmentTitle}>
                Attached Documents
              </Typography>
              {(isEdit
                ? formDetails?.attachment_url
                : projectDetails?.attachment_url
              )?.map((url) => (
                <Paper
                  style={{ padding: 12, background: "#FFF", boxShadow: "none" }}
                >
                  <a
                    href={url}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: "none" }}
                  >
                    <PdfFileIconWithDownload />
                  </a>
                </Paper>
              ))}
            </Box>
          )}
          <div className={classes.tableTitle}>
            <Typography variant="h6">Data collected from IoT Device</Typography>
            <CeroAutoComplete
              id="year"
              label="Year"
              onChange={(e, value) => onChangeFilter(value)}
              options={yearList}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              classes={{ root: classes.filter }}
            />
            <DataTable
              programId={id}
              iotDetails={isEdit ? formedDataEdit : formedData}
              updatedStatus={updatedStatus}
              year={choosenYear}
            />
          </div>
        </>
      )}
      <CreateCarbonOffsetPopup
        isOpen={addProgramPopup}
        formDetails={formDetails}
        isEdit={true}
        onClose={() => setAddProgramPopup(false)}
      />
    </DashboardLayout>
  );
};

export default CarbonOffsetDetails;
