import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  buttonPrimary: {
    background: theme.palette.Primary.background,
    textTransform: "capitalize",
    height: 35,
    borderColor: theme.palette.Primary.background,
    marginRight: 15,
    fontWeight: 400,
    color: theme.palette.background.white,
    "&:hover": {
      backgroundColor: theme.palette.Primary.hoverButton,
      borderColor: theme.palette.Primary.hoverButton,
      color: theme.palette.Primary.light,
    },
  },
  closeIcon:{
    position: "absolute",
    zIndex: 999,
    right: 40,
    top: 20,
    cursor: "pointer"
  },
  button:{
    height: "48px",
    width:"80%"
  },
  popUp: {
    margin: "auto",
  },
  buttonContainerReview: {
    display: "flex",
    justifyContent: "flex-start",
    gap: 5,
    paddingTop: 20,
    paddingBottom: 15,
  },
  buttonSecondary: {
    color: theme.palette.Primary.background,
    borderColor: theme.palette.Primary.background,
    textTransform: "capitalize",
    "&:hover": {
      borderColor: theme.palette.Primary.background,
      backgroundColor: theme.palette.Primary.contrastText,
    },
    marginRight: theme.spacing(5),
  },
  dialog: {
    width: "60% !important",
    textAlign: "center",
  },
  viewButton:{
    display: "flex",
    paddingTop:"14px",
    marginBottom:"22px"
  },
  autoComplete:{
    width: "100%",
    marginRight: theme.spacing(4),
  },
  autoCompleteOption: {
    textAlign: "left", // Ensure text is aligned to the left
    whiteSpace: "normal", // Allows text to wrap if it's long
    display: "flex", // Maintains consistent alignment for multi-line items
  },
  root: {
    "& label.Mui-focused": {
      color: theme.palette.Primary.main,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: theme.palette.Primary.main,
    },
    "& .MuiInput-root:hover::before": {
      borderWidth: 1,
      borderBottomColor: theme.palette.Primary.main,
    },
    "& .MuiFilledInput-root:hover::before": {
      borderBottomColor: theme.palette.Primary.main,
    },
    "& .MuiFilledInput-underline:after": {
      borderColor: theme.palette.Primary.main,
    },
    "& .MuiOutlinedInput-root": {
      height: "50px !important", 
      "& fieldset": {
        borderColor: theme.palette.Primary.main,
      },
      "&:hover fieldset": {
        borderColor: theme.palette.Primary.main,
      },
      "&.Mui-focused fieldset": {
        borderWidth: 2,
        borderColor: theme.palette.Primary.main,
      },
    },
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& .css-czrb7a-MuiFormLabel-root-MuiInputLabel-root,.css-kyi7ki-MuiFormLabel-root-MuiInputLabel-root.Mui-focused":
      {
        color: theme.palette.Primary.main,
      },
  },
  input:{
    "& .css-r5s6a1-MuiInputBase-root-MuiOutlinedInput-root":{
      height: "50px !important"
    },
    "& .css-g7hdyn-MuiFormHelperText-root.Mui-error":{
      margin: `${theme.spacing(0,0,3,0)} !important`
    },
    "& .MuiFormControl-root > .MuiInputBase-root > input":{
      backgroundColor: "white !important"
    },
  },
  inputWithoutMargin:{
    margin : "4px 0px",
    "& .css-r5s6a1-MuiInputBase-root-MuiOutlinedInput-root":{
      height: "50px !important"
    },
    "& .css-g7hdyn-MuiFormHelperText-root.Mui-error":{
      margin: `${theme.spacing(0,0,3,0)} !important`
    },
    "& .MuiFormControl-root > .MuiInputBase-root > input":{
      backgroundColor: "white !important"
    },
  },
  popupTitle:{
    fontWeight: 600,
    paddingTop: theme.spacing(8),
  },
  eachIndustryBox:{
    border: '1px solid rgba(139, 33, 71, 1)',
    padding: '10px',
    borderRadius: '4px',
    background: '#e4e4e445'
  }
}));

export default useStyles;
