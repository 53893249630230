import React, { useEffect, useState } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import useStyles from "./styles";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import ReactCountryFlag from "react-country-flag";
import DashboardLayout from "../../../../layouts/DashboardLayout";
import {
  getAuditorCarbonOffsetDetails,
  getAuditorCarbonOffsetIotData,
  getIotData,
  getOffsetDetails,
  refreshIotData,
  resetAuditorReviewOffsetStatus,
  resetAuditorValidateOffsetStatus,
  resetCarbonOffset,
  reviewAuditorCarbonOffsetData,
  validateAuditorCarbonOffsetData,
} from "../../../../redux/actions";
import CeroButton from "../../../../components/CeroButton";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import DataTable from "./DataTable";
import { useSnackbar } from "notistack";
import { STATUS } from "../../../../redux/constants";
import CeroAutoComplete from "../../../../components/CeroAutoComplete";
import { sampleYear } from "../../../../constants";
import { getOffsetIotDataDetails } from "../../../../redux/actions/approval";
import CeroInfoPair from "../../../../components/CeroInfoPair";
import { capitalizeFirstLetterOfEachWord } from "../../../../services/utilityService";
import CeroTable from "../../../../components/CeroTable";
import CeroPopUp from "../../../../components/CeroPopUp";
import PdfFileIconWithDownload from "../../../../assets/images/Download";

const CarbonOffsetCompanyDetails = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { companyId, programId } = useParams();
  const { id } = useParams();
  const location = useLocation();
  const { year, period } = location.state;
  const [comment, setComment] = useState("");
  const [reviewPopup, setReviewPopup] = useState(false);
  const [isApprove, setIsApprove] = useState(false);

  const TableColumns = [
    {
      columnKey: "year",
      columnId: "year",
      columnHeader: "Year",
    },
    {
      columnKey: "month",
      columnId: "month",
      columnHeader: "Month",
    },
    {
      columnKey: "Solar Energy Produced (kWh)",
      columnId: "Solar Energy Produced (kWh)",
      columnHeader: "Solar Energy Produced (kWh)",
    },
    {
      columnKey: "Co2 Offset (kg)",
      columnId: "Co2 Offset (kg)",
      columnHeader: "Co2 Offset (kg)",
    },
  ];

  const projectDetails = useSelector(
    (state) =>
      state.carbonOffset.getAuditorCarbonOffsetDetails.data.offset_program
  );

  const projectDetailsCard = useSelector(
    (state) => state.carbonOffset.getOffsetDetails.status
  );
  const approveData = useSelector(
    (state) => state.carbonOffset.validateAuditorCarbonOffsetData
  );
  const reviewData = useSelector(
    (state) => state.carbonOffset.reviewAuditorCarbonOffsetData
  );

  const carbonOffsetState = useSelector((state) => state.carbonOffset);

  const iotDetails = useSelector(
    (state) => state.carbonOffset.getAuditorCarbonOffsetIotData.data
  );
  const getOffset = useSelector(
    (state) => state.carbonOffset.getAuditorCarbonOffsetIotData.data.iot_data
  );
  const updatedStatus = useSelector(
    (state) => state.carbonOffset.updateIotData
  );
  const refreshedData = useSelector(
    (state) => state.carbonOffset.refreshIotData
  );
  const newData = useSelector(
    (state) => state.carbonOffset.getAuditorCarbonOffsetIotData.data
  );

  const yearList = sampleYear().map((item) => ({
    id: item.key,
    label: item.value,
  }));

  useEffect(() => {
    dispatch(getAuditorCarbonOffsetDetails(companyId, programId));
    dispatch(getAuditorCarbonOffsetIotData(companyId, programId, year, period));
  }, [dispatch, id, updatedStatus]);

  useEffect(() => {
    if (approveData?.status === STATUS.SUCCESS) {
      enqueueSnackbar("Data Approved successfully!", {
        variant: "success",
        autoHideDuration: 3000,
      });
    dispatch(getAuditorCarbonOffsetIotData(companyId, programId, year, period));
      dispatch(resetAuditorValidateOffsetStatus())
    } else if (approveData?.status === STATUS.ERROR) {
      enqueueSnackbar(
        approveData?.message?.message
          ? approveData?.message?.message
          : "We couldn't process your request. Please try again later.",
        { variant: "error", autoHideDuration: 3000 }
      );
      dispatch(resetAuditorValidateOffsetStatus())
    }
  }, [approveData, enqueueSnackbar, dispatch]);

  useEffect(() => {
    if (reviewData?.status === STATUS.SUCCESS) {
      enqueueSnackbar("Records Reviewed", {
        variant: "success",
        autoHideDuration: 3000,
      });
    dispatch(getAuditorCarbonOffsetIotData(companyId, programId, year, period));
      dispatch(resetAuditorReviewOffsetStatus())
    } else if (reviewData?.status === STATUS.ERROR) {
      enqueueSnackbar(
        reviewData?.message?.message
          ? reviewData?.message?.message
          : "We couldn't process your request. Please try again later.",
        { variant: "error", autoHideDuration: 3000 }
      );
      dispatch(resetAuditorReviewOffsetStatus())
    }
  }, [reviewData, enqueueSnackbar, dispatch]);

  const refreshData = () => {
    id && dispatch(refreshIotData(id));
  };
  const onChangeFilter = (selectedValue) => {
    // setYear(selectedValue.id);
  };

  const getCarbonOffsetList = () => {
    if (!getOffset || !Array.isArray(getOffset)) {
      return [];
    }

    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    return getOffset.map((item) => ({
      year: item.year || "",
      month: monthNames[parseInt(item.month) - 1] || "",
      "Solar Energy Produced (kWh)": item["Solar Energy Produced (kWh)"] === 0 ? 0 : item["Solar Energy Produced (kWh)"] || "",
      "Co2 Offset (kg)": item["Co2 Offset (kg)"] === 0 ? 0 : item["Co2 Offset (kg)"] || "",
    }));
  };

  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  const handleReviewPopup = (item) => {
    setReviewPopup(true);
    setIsApprove(false);
  };

  const handleValidatePopup = () => {
    const data = {}
    dispatch(
      validateAuditorCarbonOffsetData(
        companyId,
        year,
        period,
        programId,
        data
      )
    )
  };

  const onSubmitReview = () => {
    const data = {
      comment: comment,
    };
    setReviewPopup(false);
 dispatch(
          reviewAuditorCarbonOffsetData(
            companyId,
            year,
            period,
            programId,
            data
          )
        );
    setComment("");
    dispatch(getAuditorCarbonOffsetIotData(companyId, programId, year, period));
  };

  const getReviewComments = () => {
    return (
      <>
        <TextField
          id="outlined-multiline-static"
          multiline
          rows={5}
          placeholder="Comment"
          style={{ width: "100%" }}
          value={comment}
          onChange={handleCommentChange}
          className={classes.textField}
        />
        <div className={classes.textLimit}>
          <div className={classes.noComments}>
            {comment.trim().length === 0 ? "No comments" : ""}
          </div>
          <div className={classes.maxCharacters}>
            {"Maximum 200 characters"}
          </div>
        </div>
        <div className={classes.reviewButtons}>
          <CeroButton
            className={classes.buttonPrimary}
            variant="contained"
            onClick={onSubmitReview}
            buttonText="Save"
          />
          <CeroButton
            className={classes.buttonSecondary}
            variant="outlined"
            onClick={() => setReviewPopup(false)}
            buttonText="Cancel"
          />
        </div>
      </>
    );
  };
  return (
    <DashboardLayout>
      <div
        className={classes.backContainer}
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <div
          style={{ display: "flex", alignItems: "center" }}
          onClick={() => {
            navigate(-1);
          }}
        >
          <ArrowBackIosIcon />
          Back
        </div>
        {newData?.status !== 'verified' && newData?.status !== "review" && newData?.status !== "audited" && (
          <div>
            <CeroButton
              className={classes.buttonPrimary}
              variant="contained"
              onClick={handleValidatePopup}
              buttonText="Validate"
            />
        {newData?.status !== 'verified' && newData?.status !== "review" && newData?.status !== "audited" && (
            <CeroButton
              className={classes.buttonPrimary}
              variant="contained"
              onClick={handleReviewPopup}
              buttonText="Review"
            />
        )}
          </div>
        )}
      </div>
      {projectDetailsCard === STATUS.RUNNING ? (
        <Box className={classes.loader}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Box className={classes.boxContainer}>
            <Grid className={classes.titleGrid}>
              <Grid item>
                <Typography variant="h6">{projectDetails?.name}</Typography>
                <div className={classes.location}>
                  <ReactCountryFlag
                    countryCode={projectDetails?.country_code}
                    svg
                    style={{
                      width: "2em",
                      height: "1.8em",
                      marginRight: "0.8em",
                    }}
                  />
                  <Typography variant="subtitle1">
                    {projectDetails?.location}
                  </Typography>
                </div>
              </Grid>
              <Grid item display="flex">
                <Typography>Status : &nbsp; </Typography>
                <Typography
                  className={`${classes.infoContainer} ${
                    newData?.status === "In Progress"
                      ? classes.Inprogress
                      : classes[newData?.status]
                  }`}
                >
                  {capitalizeFirstLetterOfEachWord(newData?.status)}
                </Typography>
              </Grid>
            </Grid>
            <Typography className={classes.contentBox}>
              {projectDetails?.description}
            </Typography>
            <Grid spacing={5} className={classes.gridContainer}>
              <Grid item xs={4}>
                <Typography className={classes.gridItemTitle}>
                  Program Type
                </Typography>
                <Typography className={classes.gridItem}>
                  {projectDetails?.program_type}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography className={classes.gridItemTitle}>
                  Program
                </Typography>
                <Typography className={classes.gridItem}>
                  {projectDetails?.program_name}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography className={classes.gridItemTitle}>
                  Start year
                </Typography>
                <Typography className={classes.gridItem}>
                  {projectDetails?.start_year}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography className={classes.gridItemTitle}>
                  Installed Capacity (kW)
                </Typography>
                <Typography className={classes.gridItem}>
                  {
                    projectDetails?.program_specific_data?.find(
                      (data) => data.data_name === "Installed capacity (KW)"
                    )?.value
                  }
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography className={classes.gridItemTitle}>
                  No. of Panels installed
                </Typography>
                <Typography className={classes.gridItem}>
                  {
                    projectDetails?.program_specific_data?.find(
                      (data) => data.data_name === "number of panels"
                    )?.value
                  }
                </Typography>
              </Grid>
            </Grid>
          </Box>
         {projectDetails?.attachment_url?.length > 0 && <Box display='flex' justifyContent='flex-end' paddingRight={5.5} gap={2} alignItems='center'>
            <Typography className={classes.attachmentTitle}>Attached Documents</Typography>
            {projectDetails?.attachment_url?.map((url) => (
              <a
                href={url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <PdfFileIconWithDownload />
              </a>
            ))}
          </Box>}
          <div className={classes.tableTitle}>
            <Typography variant="h6" paddingBottom={8} paddingTop={3}>
              Data collected from IoT Device
            </Typography>
            <CeroTable
              columns={TableColumns}
              data={getCarbonOffsetList}
              hasMore={false}
              loading={false}
            />
          </div>
        </>
      )}
      <CeroPopUp
        primaryPopUp={{
          open: reviewPopup,
          onClose: () => setReviewPopup(false),
          content: getReviewComments(),
          header: { title: "Comments" },
        }}
      />
    </DashboardLayout>
  );
};

export default CarbonOffsetCompanyDetails;
