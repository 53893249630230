import { handleActions } from "redux-actions";
import immutable from "immutability-helper";

import { ActionTypes } from "../constants/actions";
import { STATUS } from "../constants";
import { parseError } from "../../services/client";

export const OffsetState = {
  listCarbonOffsetPrograms: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  addCarbonOffset: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  getProgramTypes: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  getPrograms: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  getOffsetDetails: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  getIotData: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  updateIotData:{
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  refreshIotData: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  getOffsetCountries: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  getAuditorCarbonOffsetList: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  getAuditorCarbonOffsetIotData: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  getAuditorCarbonOffsetDetails: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  validateAuditorCarbonOffsetData: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  reviewAuditorCarbonOffsetData: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  getCarbonOffsetFormDetails: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  updateCarbonOffsetFormDetails: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
};

const carbonOffsetActions = {
  carbonOffset: handleActions(
    {
      [ActionTypes.LIST_CARBON_OFFSET_PROGRAMS]: (state, { payload }) =>
        immutable(state, {
          listCarbonOffsetPrograms: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.LIST_CARBON_OFFSET_PROGRAMS_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          listCarbonOffsetPrograms: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.LIST_CARBON_OFFSET_PROGRAMS_FAILURE]: (state, { payload }) =>
        immutable(state, {
          listCarbonOffsetPrograms: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),

      [ActionTypes.ADD_CARBON_OFFSET]: (state, { payload }) =>
        immutable(state, {
          addCarbonOffset: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.ADD_CARBON_OFFSET_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          addCarbonOffset: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.ADD_CARBON_OFFSET_FAILURE]: (state, { payload }) =>
        immutable(state, {
          addCarbonOffset: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),

      [ActionTypes.GET_PROGRAM_TYPES]: (state, { payload }) =>
        immutable(state, {
          getProgramTypes: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.GET_PROGRAM_TYPES_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          getProgramTypes: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.GET_PROGRAM_TYPES_FAILURE]: (state, { payload }) =>
        immutable(state, {
          getProgramTypes: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),

      [ActionTypes.GET_PROGRAMS]: (state, { payload }) =>
        immutable(state, {
          getPrograms: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.GET_PROGRAMS_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          getPrograms: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.GET_PROGRAMS_FAILURE]: (state, { payload }) =>
        immutable(state, {
          getPrograms: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),

        [ActionTypes.GET_OFFSET_DETAILS]: (state, { payload }) =>
          immutable(state, {
            getOffsetDetails: {
              status: { $set: STATUS.RUNNING },
            },
          }),
        [ActionTypes.GET_OFFSET_DETAILS_SUCCESS]: (state, { payload }) =>
          immutable(state, {
            getOffsetDetails: {
              status: { $set: STATUS.SUCCESS },
              data: { $set: payload },
            },
          }),
        [ActionTypes.GET_OFFSET_DETAILS_FAILURE]: (state, { payload }) =>
          immutable(state, {
            getOffsetDetails: {
              status: { $set: STATUS.ERROR },
              message: { $set: parseError(payload) },
            },
          }),

          [ActionTypes.GET_IOT_DATA]: (state, { payload }) =>
            immutable(state, {
              getIotData: {
                status: { $set: STATUS.RUNNING },
              },
            }),
          [ActionTypes.GET_IOT_DATA_SUCCESS]: (state, { payload }) =>
            immutable(state, {
              getIotData: {
                status: { $set: STATUS.SUCCESS },
                data: { $set: payload },
              },
            }),
          [ActionTypes.GET_IOT_DATA_FAILURE]: (state, { payload }) =>
            immutable(state, {
              getIotData: {
                status: { $set: STATUS.ERROR },
                message: { $set: parseError(payload) },
              },
            }),

            [ActionTypes.UPDATE_IOT_DATA]: (state, { payload }) =>
              immutable(state, {
                updateIotData: {
                  status: { $set: STATUS.RUNNING },
                },
              }),
            [ActionTypes.UPDATE_IOT_DATA_SUCCESS]: (state, { payload }) =>
              immutable(state, {
                updateIotData: {
                  status: { $set: STATUS.SUCCESS },
                  data: { $set: payload },
                },
              }),
            [ActionTypes.UPDATE_IOT_DATA_FAILURE]: (state, { payload }) =>
              immutable(state, {
                updateIotData: {
                  status: { $set: STATUS.ERROR },
                  message: { $set: parseError(payload) },
                },
              }),

              [ActionTypes.REFRESH_IOT_DATA]: (state, { payload }) =>
                immutable(state, {
                  refreshIotData: {
                    status: { $set: STATUS.RUNNING },
                  },
                }),
              [ActionTypes.REFRESH_IOT_DATA_SUCCESS]: (state, { payload }) =>
                immutable(state, {
                  refreshIotData: {
                    status: { $set: STATUS.SUCCESS },
                    data: { $set: payload },
                  },
                }),
              [ActionTypes.REFRESH_IOT_DATA_FAILURE]: (state, { payload }) =>
                immutable(state, {
                  refreshIotData: {
                    status: { $set: STATUS.ERROR },
                    message: { $set: parseError(payload) },
                  },
                }),

                [ActionTypes.GET_OFFSET_COUNTRIES]: (state, { payload }) =>
                  immutable(state, {
                    getOffsetCountries: {
                      status: { $set: STATUS.RUNNING },
                    },
                  }),
                [ActionTypes.GET_OFFSET_COUNTRIES_SUCCESS]: (state, { payload }) =>
                  immutable(state, {
                    getOffsetCountries: {
                      status: { $set: STATUS.SUCCESS },
                      data: { $set: payload },
                    },
                  }),
                [ActionTypes.GET_OFFSET_COUNTRIES_FAILURE]: (state, { payload }) =>
                  immutable(state, {
                    getOffsetCountries: {
                      status: { $set: STATUS.ERROR },
                      message: { $set: parseError(payload) },
                    },
                  }),

        [ActionTypes.RESET_CARBON_OFFSET]: (state, { payload }) =>
          immutable(state, {
              addCarbonOffset: {
                  status: { $set: STATUS.IDLE },
                  message: { $set: '' }
              },
              refreshIotData: {
                status: { $set: STATUS.IDLE },
                message: { $set: '' }
            },
          }),

          [ActionTypes.RESET_IOT_DATA]: (state, { payload }) =>
            immutable(state, {
                updateIotData: {
                  status: { $set: STATUS.IDLE },
              },
            }),
            [ActionTypes.GET_AUDITOR_CARBON_OFFSET_LIST]: (state, { payload }) =>
              immutable(state, {
                getAuditorCarbonOffsetList: {
                  status: { $set: STATUS.RUNNING },
                },
              }),
            [ActionTypes.GET_AUDITOR_CARBON_OFFSET_LIST_SUCCESS]: (state, { payload }) =>
              immutable(state, {
                getAuditorCarbonOffsetList: {
                  status: { $set: STATUS.SUCCESS },
                  data: { $set: payload },
                },
              }),
            [ActionTypes.GET_AUDITOR_CARBON_OFFSET_LIST_FAILURE]: (state, { payload }) =>
              immutable(state, {
                getAuditorCarbonOffsetList: {
                  status: { $set: STATUS.ERROR },
                  message: { $set: parseError(payload) },
                },
              }),
            [ActionTypes.GET_AUDITOR_CARBON_OFFSET_IOT_DATA]: (state, { payload }) =>
              immutable(state, {
                getAuditorCarbonOffsetIotData: {
                  status: { $set: STATUS.RUNNING },
                },
              }),
            [ActionTypes.GET_AUDITOR_CARBON_OFFSET_IOT_DATA_SUCCESS]: (state, { payload }) =>
              immutable(state, {
                getAuditorCarbonOffsetIotData: {
                  status: { $set: STATUS.SUCCESS },
                  data: { $set: payload },
                },
              }),
            [ActionTypes.GET_AUDITOR_CARBON_OFFSET_IOT_DATA_FAILURE]: (state, { payload }) =>
              immutable(state, {
                getAuditorCarbonOffsetIotData: {
                  status: { $set: STATUS.ERROR },
                  message: { $set: parseError(payload) },
                },
              }),
            [ActionTypes.GET_AUDITOR_CARBON_OFFSET_DETAILS]: (state, { payload }) =>
              immutable(state, {
                getAuditorCarbonOffsetDetails: {
                  status: { $set: STATUS.RUNNING },
                },
              }),
            [ActionTypes.GET_AUDITOR_CARBON_OFFSET_DETAILS_SUCCESS]: (state, { payload }) =>
              immutable(state, {
                getAuditorCarbonOffsetDetails: {
                  status: { $set: STATUS.SUCCESS },
                  data: { $set: payload },
                },
              }),
            [ActionTypes.GET_AUDITOR_CARBON_OFFSET_DETAILS_FAILURE]: (state, { payload }) =>
              immutable(state, {
                getAuditorCarbonOffsetDetails: {
                  status: { $set: STATUS.ERROR },
                  message: { $set: parseError(payload) },
                },
              }),
            [ActionTypes.VALIDATE_AUDITOR_CARBON_OFFSET_DATA]: (state, { payload }) =>
              immutable(state, {
                validateAuditorCarbonOffsetData: {
                  status: { $set: STATUS.RUNNING },
                },
              }),
            [ActionTypes.VALIDATE_AUDITOR_CARBON_OFFSET_DATA_SUCCESS]: (state, { payload }) =>
              immutable(state, {
                validateAuditorCarbonOffsetData: {
                  status: { $set: STATUS.SUCCESS },
                  data: { $set: payload },
                },
              }),
            [ActionTypes.VALIDATE_AUDITOR_CARBON_OFFSET_DATA_FAILURE]: (state, { payload }) =>
              immutable(state, {
                validateAuditorCarbonOffsetData: {
                  status: { $set: STATUS.ERROR },
                  message: { $set: parseError(payload) },
                },
              }),
              [ActionTypes.REVIEW_AUDITOR_CARBON_OFFSET_DATA]: (state, { payload }) =>
                immutable(state, {
                  reviewAuditorCarbonOffsetData: {
                    status: { $set: STATUS.RUNNING },
                  },
                }),
              [ActionTypes.REVIEW_AUDITOR_CARBON_OFFSET_DATA_SUCCESS]: (state, { payload }) =>
                immutable(state, {
                  reviewAuditorCarbonOffsetData: {
                    status: { $set: STATUS.SUCCESS },
                    data: { $set: payload },
                  },
                }),
              [ActionTypes.REVIEW_AUDITOR_CARBON_OFFSET_DATA_FAILURE]: (state, { payload }) =>
                immutable(state, {
                  reviewAuditorCarbonOffsetData: {
                    status: { $set: STATUS.ERROR },
                    message: { $set: parseError(payload) },
                  },
                }),
                [ActionTypes.RESET_AUDITOR_VALIDATE_OFFSET_STATUS]: (state, { payload }) =>
                  immutable(state, {
                    validateAuditorCarbonOffsetData: {
                        status: { $set: STATUS.IDLE },
                    },
                  }),
                [ActionTypes.RESET_AUDITOR_REVIEW_OFFSET_STATUS]: (state, { payload }) =>
                  immutable(state, {
                    reviewAuditorCarbonOffsetData: {
                        status: { $set: STATUS.IDLE },
                    },
                  }),
                  [ActionTypes.RESET_EDIT_OFFSET_STATUS]: (state, { payload }) =>
                    immutable(state, {
                      updateCarbonOffsetFormDetails: {
                          status: { $set: STATUS.IDLE },
                      },
                    }),
                  [ActionTypes.GET_CARBON_OFFSET_FORM_DETAILS]: (state, { payload }) =>
                    immutable(state, {
                      getCarbonOffsetFormDetails: {
                        status: { $set: STATUS.RUNNING },
                      },
                    }),
                  [ActionTypes.GET_CARBON_OFFSET_FORM_DETAILS_SUCCESS]: (state, { payload }) =>
                    immutable(state, {
                      getCarbonOffsetFormDetails: {
                        status: { $set: STATUS.SUCCESS },
                        data: { $set: payload },
                      },
                    }),
                  [ActionTypes.GET_CARBON_OFFSET_FORM_DETAILS_FAILURE]: (state, { payload }) =>
                    immutable(state, {
                      getCarbonOffsetFormDetails: {
                        status: { $set: STATUS.ERROR },
                        message: { $set: parseError(payload) },
                      },
                    }),
                    [ActionTypes.UPDATE_CARBON_OFFSET_FORM_DETAILS]: (state, { payload }) =>
                      immutable(state, {
                        updateCarbonOffsetFormDetails: {
                          status: { $set: STATUS.RUNNING },
                        },
                      }),
                    [ActionTypes.UPDATE_CARBON_OFFSET_FORM_DETAILS_SUCCESS]: (state, { payload }) =>
                      immutable(state, {
                        updateCarbonOffsetFormDetails: {
                          status: { $set: STATUS.SUCCESS },
                          data: { $set: payload },
                        },
                      }),
                    [ActionTypes.UPDATE_CARBON_OFFSET_FORM_DETAILS_FAILURE]: (state, { payload }) =>
                      immutable(state, {
                        updateCarbonOffsetFormDetails: {
                          status: { $set: STATUS.ERROR },
                          message: { $set: parseError(payload) },
                        },
                      }),
    },
    OffsetState
  ),
};

export default carbonOffsetActions;
