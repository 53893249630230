import React, { useState } from "react";
import DownloadIcon from "@mui/icons-material/Download";

const PdfFileIconWithDownload = () => {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <div
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {!isHovered && (
        <div style={styles.container}>
          <div style={styles.centreElement}>PDF</div>
          <div style={styles.bottomContainer}>
            <div style={styles.pdfBadge}>PDF</div>
            <div style={styles.fileName}>ISO45001.pdf</div>

            <div style={styles.cornerFoldDark} />
            <div style={styles.cornerFold} />
            <div style={styles.cornerCover} />
          </div>
        </div>
      )}
      {isHovered && (
        <div style={styles.container}>
          <div style={styles.hoverContainer}>
            <div style={styles.pdfBadge}>PDF</div>
            <div>
              <div style={styles.fileName}>ISO45001.pdf</div>
              <div style={styles.fileSize}>95 kB</div>
            </div>
          </div>
          <div style={styles.centreElementHover}>
            <DownloadIcon color="#fff" />
          </div>
          <div style={styles.cornerFoldDark} />
          <div style={styles.cornerFold} />
          <div style={styles.cornerCover} />
        </div>
      )}
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    width: "160px",
    height: "120px",
    border: "1px solid #ddd",
    borderRadius: "4px",
    padding: "10px",
    position: "relative",
  },
  centreElement: {
    position: "absolute",
    top: "40%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "40px",
    height: "40px",
    background: "#ddd",
    borderRadius: "6px",
    color: "#fff",
    padding: "10px 7px",
    fontSize: "14px",
    fontWeight: "bold",
  },
  centreElementHover: {
    position: "absolute",
    top: "75%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "35px",
    height: "35px",
    background: "#81868a",
    borderRadius: "6px",
    color: "#fff",
    padding: "8px 6px",
    fontSize: "14px",
    fontWeight: "bold",
  },
  bottomContainer: {
    display: "flex",
    position: "absolute",
    bottom: "0",
    background: "#f5f5f5",
    width: "100%",
    height: "28px",
  },
  pdfBadge: {
    backgroundColor: "#d9534f",
    color: "#fff",
    padding: "2px 2px",
    borderRadius: "3px",
    fontSize: "12px",
    fontWeight: "bold",
    margin: "3px",
  },
  fileName: {
    fontSize: "10px",
    color: "#333",
    fontWeight: "bold",
    alignSelf: "center",
    marginLeft: "4px",
  },
  fileSize: {
    fontSize: "10px",
    color: "#c7c7c7",
    marginLeft: "5px",
  },
  hoverContainer: {
    padding: "8px",
    display: "flex",
  },
  cornerFold: {
    position: "absolute",
    bottom: "0",
    right: "0",
    width: "20px",
    height: "20px",
    backgroundColor: "#d9534f",
    clipPath: "polygon(100% 0, 0 100%, 100% 100%)",
    transform: "rotate(180deg)",
  },
  cornerFoldDark: {
    position: "absolute",
    bottom: "0",
    right: "20px",
    width: "20px",
    height: "20px",
    backgroundColor: "#c4c4c4",
    clipPath: "polygon(100% 0, 0 100%, 100% 100%)",
  },
  cornerCover: {
    position: "absolute",
    bottom: "-1px",
    right: "-1px",
    width: "22px",
    height: "22px",
    backgroundColor: "#fff",
    clipPath: "polygon(100% 0, 0 100%, 100% 100%)",
    zIndex: 1,
  },
};

export default PdfFileIconWithDownload;
