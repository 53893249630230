import { handleActions } from 'redux-actions'
import immutable from 'immutability-helper'

import { ActionTypes } from '../constants/actions';
import { STATUS } from '../constants'
import { parseError } from '../../services/client';

export const companyState = {
    companyList: {
        data: [],
        status: STATUS.IDLE,
        message: ''
    },
    companyDetails: {
        data: {},
        status: STATUS.IDLE,
        message: ''
    },
    auditHistory: {
        data: [],
        status: STATUS.IDLE,
        message: ''
    },
    auditDetails: {
        data: {},
        status: STATUS.IDLE,
        message: ''
    },
    approveAudit: {
        data: {},
        status: STATUS.IDLE,
        message: ''
    },
    dashboardSummary: {
        data: {},
        status: STATUS.IDLE,
        message: ''
    },
    totalCompanies: {
        data: [],
        status: STATUS.IDLE,
        message: ''
    },
    adminCompanyDetails: {
        data: {},
        status: STATUS.IDLE,
        message: ''
    },
    approvalFlow: {
        data: {},
        status: STATUS.IDLE,
        message: ''
    },
    approvalFlowUpdate: {
        data: {},
        status: STATUS.IDLE,
        message: ''
    },
    companyFunctionalitySetup: {
        data: {},
        status: STATUS.IDLE,
        message: ''
    },
    functionalityView: {
        data: {},
        status: STATUS.IDLE,
        message: ''
    },
    getFunctionalityData: {
        data: {},
        status: STATUS.IDLE,
        message: ''
    },
}

const companyActions = {
    company: handleActions(
        {
            [ActionTypes.GET_COMPANY_LIST]: (state, { payload }) =>
                immutable(state, {
                    companyList: {
                        status: { $set: STATUS.RUNNING }
                    }
                }),
            [ActionTypes.GET_COMPANY_LIST_SUCCESS]: (state, { payload }) =>
                immutable(state, {
                    companyList: {
                        status: { $set: STATUS.SUCCESS },
                        data: { $set: payload },
                    }
                }),
            [ActionTypes.GET_COMPANY_LIST_FAILURE]: (state, { payload }) =>
                immutable(state, {
                    companyList: {
                        status: { $set: STATUS.ERROR },
                        message: { $set: parseError(payload) }
                    }
                }),

            [ActionTypes.GET_COMPANY_DETAILS]: (state, { payload }) =>
                immutable(state, {
                    companyDetails: {
                        status: { $set: STATUS.RUNNING }
                    }
                }),
            [ActionTypes.GET_COMPANY_DETAILS_SUCCESS]: (state, { payload }) =>
                immutable(state, {
                    companyDetails: {
                        status: { $set: STATUS.SUCCESS },
                        data: { $set: payload },
                    }
                }),
            [ActionTypes.GET_COMPANY_DETAILS_FAILURE]: (state, { payload }) =>
                immutable(state, {
                    companyDetails: {
                        status: { $set: STATUS.ERROR },
                        message: { $set: parseError(payload) }
                    }
                }),

            [ActionTypes.GET_COMPANY_AUDIT_HISTORY]: (state, { payload }) =>
                immutable(state, {
                    auditHistory: {
                        status: { $set: STATUS.RUNNING }
                    }
                }),
            [ActionTypes.GET_COMPANY_AUDIT_HISTORY_SUCCESS]: (state, { payload }) =>
                immutable(state, {
                    auditHistory: {
                        status: { $set: STATUS.SUCCESS },
                        data: { $set: payload },
                    }
                }),
            [ActionTypes.GET_COMPANY_AUDIT_HISTORY_FAILURE]: (state, { payload }) =>
                immutable(state, {
                    auditHistory: {
                        status: { $set: STATUS.ERROR },
                        message: { $set: parseError(payload) }
                    }
                }),

            [ActionTypes.GET_COMPANY_AUDIT_DETAILS]: (state, { payload }) =>
                immutable(state, {
                    auditDetails: {
                        status: { $set: STATUS.RUNNING }
                    }
                }),
            [ActionTypes.GET_COMPANY_AUDIT_DETAILS_SUCCESS]: (state, { payload }) =>
                immutable(state, {
                    auditDetails: {
                        status: { $set: STATUS.SUCCESS },
                        data: { $set: payload },
                    }
                }),
            [ActionTypes.GET_COMPANY_AUDIT_DETAILS_FAILURE]: (state, { payload }) =>
                immutable(state, {
                    auditDetails: {
                        status: { $set: STATUS.ERROR },
                        message: { $set: parseError(payload) }
                    }
                }),

            [ActionTypes.GET_DASHBOARD_SUMMARY]: (state, { payload }) =>
                immutable(state, {
                    dashboardSummary: {
                        status: { $set: STATUS.RUNNING }
                    }
                }),
            [ActionTypes.GET_DASHBOARD_SUMMARY_SUCCESS]: (state, { payload }) =>
                immutable(state, {
                    dashboardSummary: {
                        status: { $set: STATUS.SUCCESS },
                        data: { $set: payload },
                    }
                }),

            [ActionTypes.GET_DASHBOARD_SUMMARY_FAILURE]: (state, { payload }) =>
                immutable(state, {
                    dashboardSummary: {
                        status: { $set: STATUS.ERROR },
                        message: { $set: parseError(payload) }
                    }
                }),
                

            [ActionTypes.APPROVE_COMPANY_AUDIT_RESET]: (state, { payload }) =>
                immutable(state, {
                    approveAudit: {
                        status: { $set: STATUS.IDLE },
                    }
                }),
            [ActionTypes.APPROVE_COMPANY_AUDIT]: (state, { payload }) =>
                immutable(state, {
                    approveAudit: {
                        status: { $set: STATUS.RUNNING }
                    }
                }),
            [ActionTypes.APPROVE_COMPANY_AUDIT_SUCCESS]: (state, { payload }) =>
                immutable(state, {
                    approveAudit: {
                        status: { $set: STATUS.SUCCESS },
                        data: { $set: payload },
                    }
                }),
            [ActionTypes.APPROVE_COMPANY_AUDIT_FAILURE]: (state, { payload }) =>
                immutable(state, {
                    approveAudit: {
                        status: { $set: STATUS.ERROR },
                        message: { $set: parseError(payload) }
                    }
                }),

                [ActionTypes.GET_TOTAL_COMPANIES]: (state, { payload }) =>
                immutable(state, {
                    totalCompanies: {
                        status: { $set: STATUS.RUNNING }
                    }
                }),
            [ActionTypes.GET_TOTAL_COMPANIES_SUCCESS]: (state, { payload }) =>
                immutable(state, {
                    totalCompanies: {
                        status: { $set: STATUS.SUCCESS },
                        data: { $set: payload },
                    }
                }),
            [ActionTypes.GET_TOTAL_COMPANIES_FAILURE]: (state, { payload }) =>
                immutable(state, {
                    totalCompanies: {
                        status: { $set: STATUS.ERROR },
                        message: { $set: parseError(payload) }
                    }
                }),

                [ActionTypes.COMPANY_DETAILS]: (state, { payload }) =>
                immutable(state, {
                    adminCompanyDetails: {
                        status: { $set: STATUS.RUNNING }
                    }
                }),
            [ActionTypes.COMPANY_DETAILS_SUCCESS]: (state, { payload }) =>
                immutable(state, {
                    adminCompanyDetails: {
                        status: { $set: STATUS.SUCCESS },
                        data: { $set: payload },
                    }
                }),
            [ActionTypes.COMPANY_DETAILS_FAILURE]: (state, { payload }) =>
                immutable(state, {
                    adminCompanyDetails: {
                        status: { $set: STATUS.ERROR },
                        message: { $set: parseError(payload) }
                    }
                }),

            [ActionTypes.GET_APPROVAL_FLOW]: (state, { payload }) =>
                immutable(state, {
                    approvalFlow: {
                        status: { $set: STATUS.RUNNING }
                    }
                }),
            [ActionTypes.GET_APPROVAL_FLOW_SUCCESS]: (state, { payload }) =>
                immutable(state, {
                    approvalFlow: {
                        status: { $set: STATUS.SUCCESS },
                        data: { $set: payload },
                    }
                }),
            [ActionTypes.GET_APPROVAL_FLOW_FAILURE]: (state, { payload }) =>
                immutable(state, {
                    approvalFlow: {
                        status: { $set: STATUS.ERROR },
                        message: { $set: parseError(payload) }
                    }
                }),

            [ActionTypes.UPDATE_APPROVAL_FLOW]: (state, { payload }) =>
                immutable(state, {
                    approvalFlowUpdate: {
                        status: { $set: STATUS.RUNNING }
                    }
                }),
            [ActionTypes.UPDATE_APPROVAL_FLOW_SUCCESS]: (state, { payload }) =>
                immutable(state, {
                    approvalFlowUpdate: {
                        status: { $set: STATUS.SUCCESS },
                        data: { $set: payload },
                    }
                }),
            [ActionTypes.UPDATE_APPROVAL_FLOW_FAILURE]: (state, { payload }) =>
                immutable(state, {
                    approvalFlowUpdate: {
                        status: { $set: STATUS.ERROR },
                        message: { $set: parseError(payload) }
                    }
                }),
            [ActionTypes.RESET_APPROVAL_UPDATE]: (state, {}) =>
                immutable(state, {
                    approvalFlowUpdate: {
                        status: { $set: STATUS.IDLE },
                    }
                }),
            [ActionTypes.RESET_GET_APPROVAL]: (state, {}) =>
                immutable(state, {
                    approvalFlow: {
                        status: { $set: STATUS.IDLE },
                    }
                }),
                [ActionTypes.COMPANY_FUNCTIONALITY_SETUP]: (state, { payload }) =>
                    immutable(state, {
                        companyFunctionalitySetup: {
                            status: { $set: STATUS.RUNNING }
                        }
                    }),
                [ActionTypes.COMPANY_FUNCTIONALITY_SETUP_SUCCESS]: (state, { payload }) =>
                    immutable(state, {
                        companyFunctionalitySetup: {
                            status: { $set: STATUS.SUCCESS },
                            data: { $set: payload },
                        }
                    }),
                [ActionTypes.COMPANY_FUNCTIONALITY_SETUP_FAILURE]: (state, { payload }) =>
                    immutable(state, {
                        companyFunctionalitySetup: {
                            status: { $set: STATUS.ERROR },
                            message: { $set: parseError(payload) }
                        }
                    }),
                [ActionTypes.FUNCTIONALITY_VIEW]: (state, { payload }) =>
                    immutable(state, {
                        functionalityView: {
                            status: { $set: STATUS.RUNNING }
                        }
                    }),
                [ActionTypes.FUNCTIONALITY_VIEW_SUCCESS]: (state, { payload }) =>
                    immutable(state, {
                        functionalityView: {
                            status: { $set: STATUS.SUCCESS },
                            data: { $set: payload },
                        }
                    }),
                [ActionTypes.FUNCTIONALITY_VIEW_FAILURE]: (state, { payload }) =>
                    immutable(state, {
                        functionalityView: {
                            status: { $set: STATUS.ERROR },
                            message: { $set: parseError(payload) }
                        }
                    }),
                [ActionTypes.RESET_COMPANY_FUNCTIONALITY_SETUP]: (state, {}) =>
                    immutable(state, {
                        companyFunctionalitySetup: {
                            status: { $set: STATUS.IDLE },
                        }
                    }),
                [ActionTypes.GET_FUNCTIONALITY_DATA]: (state, { payload }) =>
                    immutable(state, {
                        getFunctionalityData: {
                            status: { $set: STATUS.RUNNING }
                        }
                    }),
                [ActionTypes.GET_FUNCTIONALITY_DATA_SUCCESS]: (state, { payload }) =>
                    immutable(state, {
                        getFunctionalityData: {
                            status: { $set: STATUS.SUCCESS },
                            data: { $set: payload },
                        }
                    }),
                [ActionTypes.GET_FUNCTIONALITY_DATA_FAILURE]: (state, { payload }) =>
                    immutable(state, {
                        getFunctionalityData: {
                            status: { $set: STATUS.ERROR },
                            message: { $set: parseError(payload) }
                        }
                    }),

        },
        companyState
    )
}

export default companyActions;
