import * as yup from "yup";

export const addOffsetValidation = yup.object({
  program_name: yup.string().required("Program name is required"),
  country: yup.string().required("Country is required"),
  start_year: yup.number().required("Start year is required"),
  start_month: yup.string().required("Start month is required"),
  program_type: yup.string().required("Program type  is required"),
  program: yup.string().required("Program is required"),
  installed_capacity: yup.number().required("Installed capacity is required"),
  number_of_panels: yup.number().required("Number of panels  is required"),
  height_of_turbine: yup.number().required("Height of Turbines  is required"),
  rotor_diameter: yup.number().required("Rotor diameter  is required"),
  number_of_turbines: yup.number().required("Number of Turbines  is required"),
  number_of_converters: yup.number().required("No. of Converters  is required"),
  device_dimensions: yup.number().required("Device Dimensions  is required"),
  trees_planted: yup.number().required("Number of Trees Planted  is required"),
  wave_energy_converter_type: yup
    .string()
    .required("Type of Wave Energy Converter  is required"),
  total_area_planted: yup.number().required("Total Area Planted  is required"),
  planting_density: yup.number().required("Planting Density  is required"),
  climate_zone: yup.string().required("Climate Zone  is required"),
  hydro_type: yup.string().required("Type of Hydro power  is required"),
  description: yup.string().required("Description about project is required"),
  facility: yup.string().required("Facility is required"),
});
