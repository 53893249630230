import { handleActions } from "redux-actions";
import immutable from "immutability-helper";

import { ActionTypes } from "../constants/actions";
import { STATUS } from "../constants";
import { parseError } from '../../services/client';

export const productionDataState = {
  productionDataList: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  productionDataAdd: {
    data: {},
    status: STATUS.IDLE,
    message: "",
  },

};

const productionDataActions = {
  productionData: handleActions(
    {
      [ActionTypes.GET_PRODUCTION_DATA_LIST]: (state, { payload }) =>
        immutable(state, {
            productionDataList: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.GET_PRODUCTION_DATA_LIST_SUCCESS]: (state, { payload }) =>
        immutable(state, {
            productionDataList: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.GET_PRODUCTION_DATA_LIST_FAILURE]: (state, { payload }) =>
        immutable(state, {
            productionDataList: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),

        [ActionTypes.ADD_PRODUCTION_DATA]: (state, { payload }) =>
          immutable(state, {
              productionDataAdd: {
              status: { $set: STATUS.RUNNING },
            },
          }),
        [ActionTypes.ADD_PRODUCTION_DATA_SUCCESS]: (state, { payload }) =>
          immutable(state, {
            productionDataAdd: {
              status: { $set: STATUS.SUCCESS },
              data: { $set: payload },
            },
          }),
        [ActionTypes.ADD_PRODUCTION_DATA_FAILURE]: (state, { payload }) =>
          immutable(state, {
            productionDataAdd: {
              status: { $set: STATUS.ERROR },
              message: { $set: parseError(payload) },
            },
          }),
          [ActionTypes.RESET_ADD_PRODUCTION_DATA_STATUS]: (state, { payload }) =>
            immutable(state, {
              productionDataAdd: {
                status: { $set: STATUS.IDLE },
              },
            }),
      
    },
    productionDataState
  ),
};

export default productionDataActions;
