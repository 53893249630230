import { createActions } from 'redux-actions';

import { ActionTypes } from '../constants/actions';

export const {
    getProductionDataList,
    addProductionData,
    resetAddProductionDataStatus
} = createActions({
    [ActionTypes.GET_PRODUCTION_DATA_LIST]: (year, country, facility) => ({year, country, facility }),
    [ActionTypes.ADD_PRODUCTION_DATA]: (requestData) => ({requestData}),
    [ActionTypes.RESET_ADD_PRODUCTION_DATA_STATUS]: () => ({}),
});
