import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.background.white,
    height: "fit-content",
    width: "100%",
    borderRadius: 14,
    padding: theme.spacing(5, 7),
    paddingBottom: 0,
    position: "relative",
    "@media (min-width: 1200px)": {
      maxWidth: "none",
    },
  },
  innerContainer: {
    height: "100%",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      width: "6px",
    },
    "&::-webkit-scrollbar-track": {
      background: "none",
    },
    "&::-webkit-scrollbar-thumb": {
      background: theme.palette.background.darkGray,
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: theme.palette.background.darkGray,
    },
  },
  title: {
    marginBottom: theme.spacing(3),
    marginRight: theme.spacing(2),
    fontWeight: 500
  },
  buttonContainer: {
    padding: theme.spacing(4, 0),
  },
  buttonPrimary: {
    background: theme.palette.Primary.background,
    textTransform: "capitalize",
    width: 140,
    height: 42,
    marginRight: 15,
    fontWeight: 400,
    "&:hover": {
      backgroundColor: theme.palette.Primary.hoverButton,
      color: theme.palette.Primary.light,
    },
  },
  buttonSecondary: {
    color: theme.palette.Primary.background,
    borderColor: theme.palette.Primary.background,
    textTransform: "capitalize",
    width: 120,
    height: 42,
    "&:hover": {
      borderColor: theme.palette.Primary.background,
      backgroundColor: theme.palette.Secondary.hoverButton,
    },
    marginRight: theme.spacing(5),
  },
  yesButton:{
    background: theme.palette.Primary.background,
    textTransform: "capitalize",
    width: 80,
    height: 42,
    marginRight: 15,
    fontWeight: 400,
    "&:hover": {
      backgroundColor: theme.palette.Primary.hoverButton,
      color: theme.palette.Primary.light,
    },
  },
  noButton:{
    color: theme.palette.Primary.background,
    borderColor: theme.palette.Primary.background,
    textTransform: "capitalize",
    width: 80,
    height: 42,
    "&:hover": {
      borderColor: theme.palette.Primary.background,
      backgroundColor: theme.palette.Secondary.hoverButton,
    },
    marginRight: theme.spacing(5),
  },
  backContainer: {
    color: theme.palette.Primary.background,
    textTransform: "capitalize",
    display: "flex",
    paddingBottom: 35,
    paddingTop: 10,
    alignItems: "center",
    cursor: "pointer",
  },
  MultiSelectformControl: {
    marginTop: theme.spacing(2),
    width: "100%",
    height: 60,
    "& .MuiFormLabel-root": {
      left: theme.spacing(2),
      top: theme.spacing(1),
      background: "white",
    },
    background: "white",
    "& .MuiInputBase-formControl.justCheckSelected > fieldset > legend": {
      maxWidth: "100%",
      paddingLeft: 55,
    },
    "& .Mui-focused > fieldset > legend": {
      maxWidth: "100%",
      paddingLeft: 55,
    },
  },
  addRole:{
    display: "flex",
    color: theme.palette.icon.blue,
  },
  roleBox:{
    backgroundColor: theme.palette.Primary.light,
    padding: theme.spacing(5),
    borderRadius: 8,
    border: "1px solid #8B2147",
    margin: theme.spacing(5,0),
  },
  justCheckSelected: {
    "& > fieldset > legend": {
      display: "none !important",
    },
  },
  label: {
    margin: "0px !important",
    display: "none !important",
    "&. MuiFormLabel-filled": {
      margin: "0px !important",
    },
  },
  multiSelect: {
    width: "90%",
    margin: theme.spacing(3, 0),
    height: 55,
    paddingTop: theme.spacing(1),
    borderColor: "black !important",
    borderRadius: "4 !important",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "black !important",
      borderRadius: 4,
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "black !important",
      borderRadius: 4,
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "black !important",
      borderRadius: 4,
    },
  },
  roleDeletebtn:{
    position: "absolute",
    right: "5%",
    background: theme.palette.Primary.background,
    textTransform: "capitalize",
    width: 90,
    height: 30,
    fontWeight: 500,
    fontSize: 12,
    whiteSpace: "nowrap",
    "&:hover": {
      backgroundColor: theme.palette.Primary.hoverButton,
      color: theme.palette.Primary.light,
    },  
  },
  roleActions:{
    position: "absolute",
    right: "0%",
    width: "50%",
    textTransform: "capitalize",
    whiteSpace: "nowrap",
    display: "flex",
    alignItems: "center"
  },
  textAreaContainer:{
    width: "80%",
    marginRight: theme.spacing(8),
  },
  select:{
    width: "90%"
  },
  roleSelect:{
    width: "86%"
  },
  autoComplete:{
    width: "92%"
  },
  icon:{
    color: theme.palette.Primary.background,
    cursor: "pointer",
  },
  deleteBox:{
    width: "80%",
    margin: "auto",
  },
  closeIcon: {
    position: "absolute",
    zIndex: 999,
    right: 40,
    top: 20,
    cursor: "pointer",
  },
  dialog:{
    width: "56%",
    "& .MuiPaper-root.MuiDialog-paper": {
     width: "40vw",
     margin: 80,
   },
   },
   switchColor: {
    "& .MuiSwitch-track": {
      backgroundColor: "red !important",
      opacity: "5 !important",
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: "white !important",
    },
    "& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track": {
      backgroundColor: `${theme.palette.status.closed} !important`,
      opacity: "1.5 !important",
    },
  },
  statusText: {
    fontSize: 14,
  },
  roleTypeGrid:{
    paddingLeft: "0 !important"
  },
  activeStatus:{
    color: theme.palette.status.closed,
    fontWeight:500,
  },
  lockedStatus:{
    color:  '#E81111',
    fontWeight:500,
  },
  invitedStatus:{
    color: theme.palette.status.pendingApproval,
    fontWeight:500,
  },
  inactiveStatus:{
    color: theme.palette.status.added,
    fontWeight:500,
  },
  checkboxContainer: {
    display: "flex",
    alignItems: "center",
  },
  checkbox: {
    color: "#8C2148 !important",
    borderWidth: 1,
  },
  addCountryFacility:{
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}));

export default useStyles;
