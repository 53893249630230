import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(0, 2),
    height: "100%",
  },
  tableContainer: {
    padding: 0,
    width: "100%",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    background: theme.palette.background.white,
    marginTop: theme.spacing(5),
  },
  cellContainer: {
    boxShadow: "none",
  },
  loader: {
    width: "100%",
    height: 100,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  tableHeaderContainer: {
    padding: theme.spacing(3),
    background: theme.palette.Secondary.background,
    fontWeight: 500,
  },
  backContainer:{
    color: theme.palette.Primary.background,
    textTransform: "capitalize",
    display: "flex",
    paddingBottom: 10,
    alignItems: 'center',
    cursor: "pointer"
  },
  headerContainer:{
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  title:{
    fontWeight: 500,
    marginTop: theme.spacing(8)
  },
  audited: {
    color: "#024FE4",
    fontWeight:500,
    fontSize: 14
  },
  assigned: {
    color: theme.palette.Success.warning,
    fontWeight:500,
    fontSize: 14
  },
  approved: {
    color: theme.palette.status.approved,
    fontWeight:500,
    fontSize: 14
  },
  verified: {
    color: theme.palette.status.verified,
    fontWeight:500,
    fontSize: 14
  },
  review:{
    color: theme.palette.status.review,
    fontWeight:500,
    fontSize: 14
  },
  pending: {
    color: "#F7931D",
    fontWeight:500,
    fontSize: 14
  },
  inprogress:{
    color: theme.palette.status.pendingApproval,
    fontWeight:500,
    fontSize: 14
  },
  submitted:{
    color: theme.palette.status.open,
    fontWeight:500,
    fontSize: 14
  },
  added:{
    color: theme.palette.icon.blue,
    fontWeight:500,
    fontSize: 14
  },
  signal:{
    backgroundColor: "green",
    width: 16,
    height: 16,
    borderRadius: "50%",
    border: "1px solid black",
    marginRight: theme.spacing(1)       
  },
  signalAdded:{
    backgroundColor: theme.palette.status.added,
    width: 16,
    height: 16,
    borderRadius: "50%",
    border: "1px solid black",
    marginRight: theme.spacing(1)       
  },
  signalApproved:{
    backgroundColor: theme.palette.status.approved,
    width: 16,
    height: 16,
    borderRadius: "50%",
    border: "1px solid black",
    marginRight: theme.spacing(1)       
  },
  signalReview:{
    backgroundColor: theme.palette.status.review,
    width: 16,
    height: 16,
    borderRadius: "50%",
    border: "1px solid black",
    marginRight: theme.spacing(1)   
  },
  signalPending:{
    backgroundColor: theme.palette.status.pendingApproval,
    width: 16,
    height: 16,
    borderRadius: "50%",
    border: "1px solid black",
    marginRight: theme.spacing(1) 
  },
  signalAudited:{
    backgroundColor: theme.palette.status.audited,
    width: 16,
    height: 16,
    borderRadius: "50%",
    border: "1px solid black",
    marginRight: theme.spacing(1) 
  },
  signalVerified:{
    backgroundColor: theme.palette.status.verified,
    width: 16,
    height: 16,
    borderRadius: "50%",
    border: "1px solid black",
    marginRight: theme.spacing(1) 
  }
}));

export default useStyles;
