import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "100%",
    "@media (min-width: 1200px)": {
      maxWidth: "none",
    },
  },
  tableContainer: {
    padding: 0,
    width: "100%",
    "@media (min-width: 1200px)": {
      maxWidth: "none",
    },
  },
  tableInnerContainer: {
    maxHeight: "100%",
  },
  buttonContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  buttonPrimary: {
    background: theme.palette.Primary.background,
        "&:hover": {
            backgroundColor: theme.palette.Primary.hoverButton,
            color: theme.palette.Primary.light,
          },
    width: 170,
    height: 46,
    fontSize: 14,
    marginLeft: theme.spacing(2),
    marginTop:0,
    fontWeight: 400,
    textTransform: "capitalize",
  },
  loader: {
    width: "100%",
    height: 100,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  closeIcon:{
    position: "absolute",
    zIndex: 999,
    right: 40,
    top: 20
  },
  topContainer:{
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  filterContainer:{
    display: "flex",
    width: "85%",
    justifyContent: "space-between"
  },
  selectContainer:{
    width: "100%",
    marginRight: theme.spacing(4),
  },
  textField:{
    backgroundColor: "white",
    marginRight: theme.spacing(4),
  },
  autoComplete:{
    width: "100%",
    marginRight: theme.spacing(4),
  },
  backContainer:{
    color: theme.palette.Primary.background,
    textTransform: "capitalize",
    display: "flex",
    paddingBottom: 35,
    paddingTop: 10,
    alignItems: 'center',
    cursor: "pointer",
    justifyContent:'space-between'
},
backButtonDiv:{
    alignItems: 'center',
    display: 'flex',
    // paddingLeft: '28px'
},
templateButtonDiv:{
    paddingRight: '20px'
},
bulkButton:{
    background: theme.palette.Primary.background,
    textTransform: "capitalize",
    whiteSpace: "nowrap",
    marginRight: 15,
    fontWeight: 400,
    width: 170,
    height: 42,
    "&:hover": {
        backgroundColor: theme.palette.Primary.hoverButton,
        color: theme.palette.Primary.light,
    },
},
}));

export default useStyles;
