import { all, put, call, takeLatest } from "redux-saga/effects";

import { request } from "../../services/client";
import { ActionTypes } from "../constants/actions";
import { APIEndpoints } from "../constants";

export function* listProductionData({ payload }) {
  try {
    let apiUrl = APIEndpoints.GET_PRODUCTION_DATA_LIST(payload.year, payload.country, payload.facility)
    const response = yield call(
      request, apiUrl,
      {
        method: "GET",
      }
    );
    yield put({
      type: ActionTypes.GET_PRODUCTION_DATA_LIST_SUCCESS,
      payload: response.data,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_PRODUCTION_DATA_LIST_FAILURE,
      payload: err.message,
    });
  }
}

export function* addProductionData(action) {
  try {
      const { requestData } = action.payload;
      const response = yield call(request, APIEndpoints.ADD_PRODUCTION_DATA(), {
          method: 'POST',
          payload: requestData
      })
      yield put({
          type: ActionTypes.ADD_PRODUCTION_DATA_SUCCESS,
          payload: response,
      })
  } catch (err) {
      /* istanbul ignore next */
      yield put({
          type: ActionTypes.ADD_PRODUCTION_DATA_FAILURE,
          payload: err
      })
  }
}


export default function* root() {
    yield all([
      takeLatest(ActionTypes.GET_PRODUCTION_DATA_LIST,listProductionData),
      takeLatest(ActionTypes.ADD_PRODUCTION_DATA,addProductionData),
    ])
}