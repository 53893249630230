import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Container,
  Typography,
  Box,
  Grid,
} from "@mui/material";

import DashboardLayout from "../../layouts/DashboardLayout";
import CeroButton from "../../components/CeroButton";
import { STATUS } from "../../redux/constants";

import useStyles from "./styles";
import CeroSelect from "../../components/CeroSelect";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { addProductionData, resetAddProductionDataStatus } from "../../redux/actions";
import { months, sampleYear } from "../../constants";
import { addProductionDataValidation } from "./schema";
import { useFormik } from "formik";
import CeroInput from "../../components/CeroInput";
import { useSnackbar } from "notistack";


const ProductionDataAdd = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  
  const [filterCountryFacility, setCountryFacility] = useState([]);
  const countryData = useSelector(state => state.listings.getOrganizationCountry.data.country_data);
  const countryFacilityData = useSelector(state => state.listings.getOrganizationCountry.data.country_facility_data);
  const addState = useSelector(state => state.productionData.productionDataAdd);
  
  const formik = useFormik({
    initialValues: {
        country: "",
        facility: "",
        year: "",
        month: "",
        produce: "",
        value: "",
        unit: "",
    },
    validationSchema: addProductionDataValidation,
    onSubmit: (values, { resetForm }) => {
        const requestData = {
            facility: formik.values.facility,
            year: formik.values.year,
            month: formik.values.month,
            country: formik.values.country,
            unit: formik.values.unit,
            produce: formik.values.produce,
            value: formik.values.value,
          };
          dispatch(addProductionData(requestData));
        resetForm(); // Reset the form after submission
      },
  });

  const transformObject = (obj) => {
    return Object.entries(obj).map(([outerKey, innerObj]) => {
        // Iterate over each key-value pair in the inner object
        return Object.entries(innerObj).map(([innerKey, innerValue]) => {
            return {
                key: innerValue,
                value: innerKey
            };
        });
    }).flat(); // 
};

useEffect(() => {
    setCountryFacility(transformObject(countryFacilityData))
  }, [dispatch, countryFacilityData]);

  useEffect(() => {
    // setCountryFacility(transformObject(countryFacilityData))
    formik.values.country && setCountryFacility(Object.entries(countryFacilityData[formik.values.country]).map(([innerKey, innerValue]) => {
        return {
            key: innerValue,
            value: innerKey
        };
    }));
    
  }, [dispatch, formik?.values?.country]);


  const countryList = Object.keys(countryData).map((item) => ({
    key: countryData[item],
    value: item,
  }));

  useEffect(() => {
    if (addState.status === STATUS.SUCCESS) {
      enqueueSnackbar("Data added successfully", { variant: "success", autoHideDuration: 3000});
      dispatch(resetAddProductionDataStatus())
      navigate(-1)
    } else if (addState.status === STATUS.ERROR) {
      enqueueSnackbar(addState.message.message || "We couldn't process your request. Please try again later.", { variant: "error", autoHideDuration: 3000 });
      dispatch(resetAddProductionDataStatus());
    }
  }, [addState, dispatch]);

  return (
    <DashboardLayout>
      <Container className={classes.container}>
      <div className={classes.backContainer} >
            <div className={classes.backButtonDiv} onClick={()=>{navigate(-1)}}>
            <ArrowBackIosIcon />
            Back
            </div>
        </div>
        <Container className={classes.formContainer}>
        <Box className={classes.innerContainer}>
          <Typography className={classes.title} variant="h6" component="div">
            Add Production Data
          </Typography>
          <Box className={classes.topContainer}>
            <Grid
              container
              direction="row"
              wrap="nowrap"
              justifyContent="space-between"
              spacing={8}
            >
                <Grid item container direction="column" md={6} xs={12}>
                    <CeroSelect
                    required
                    id="country"
                    name="country"
                    label="Country"
                    fullWidth
                    options={countryList}
                    selectedValue={formik.values.country}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.country && formik.errors.country}
                    />
                    <CeroSelect
                    required
                    id="month"
                    name="month"
                    label="Month"
                    fullWidth
                    options={months}
                    selectedValue={formik.values.month}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.month && formik.errors.month}
                    />
                    <CeroInput
                    required
                    id="produce"
                    name="produce"
                    label="Produce"
                    value={formik.values.produce}
                    fullWidth
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                        formik.touched.produce && formik.errors.produce
                    }
                    type="string"
                    />
                    <CeroInput
                    required
                    id="unit"
                    name="unit"
                    label="Unit"
                    value={formik.values.unit}
                    fullWidth
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                        formik.touched.unit && formik.errors.unit
                    }
                    type="string"
                    />
                    <CeroButton
                        buttonText="Add Data"
                        disabled={!formik.dirty || !formik.isValid}
                        className={classes.buttonPrimary}
                        onClick={formik.handleSubmit} 
                    />
                </Grid>

                <Grid item container direction="column" md={6} xs={12}>
                    <CeroSelect
                    required
                    id="facility"
                    name="facility"
                    label="Facility"
                    fullWidth
                    options={filterCountryFacility}
                    selectedValue={formik.values.facility}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.facility && formik.errors.facility}
                    />
                    <CeroSelect
                    required
                    id="year"
                    name="year"
                    label="Year"
                    fullWidth
                    options={sampleYear()}
                    selectedValue={formik.values.year}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.year && formik.errors.year}
                    />
                    <CeroInput
                    required
                    id="value"
                    name="value"
                    label="Value"
                    value={formik.values.value}
                    fullWidth
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                        formik.touched.value && formik.errors.value
                    }
                    type="number"
                    />
                </Grid>
            </Grid>
            </Box>
            </Box>
            </Container>
      </Container>
    </DashboardLayout>
  );
};

export default ProductionDataAdd;
