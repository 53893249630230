import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Container,
  Typography,
  Box,
  CircularProgress,
  Grid,
} from "@mui/material";

import DashboardLayout from "../../layouts/DashboardLayout";
import CeroTable from "../../components/CeroTable";
import CeroButton from "../../components/CeroButton";
import { STATUS } from "../../redux/constants";

import useStyles from "./styles";
import CeroSelect from "../../components/CeroSelect";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { getProductionDataList } from "../../redux/actions";
import { sampleYear } from "../../constants";

export const ProductionListColumns = [
    {
      columnKey: "country",
      columnId: "country",
      columnHeader: "Country",
    },
    {
      columnKey: "facility",
      columnId: "facility",
      columnHeader: "Facility",
    },
    {
      columnKey: "year",
      columnId: "year",
      columnHeader: "Year",
    },
    {
      columnKey: "produce",
      columnId: "produce",
      columnHeader: "Produce",
    },
    {
        columnKey: "value",
        columnId: "value",
        columnHeader: "Value",
      },
      {
        columnKey: "unit",
        columnId: "unit",
        columnHeader: "Unit",
      },
  ];

const ProductionDataList = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  const [choosenCountry, setCountry] = useState('');
  const [choosenFacility, setFacility] = useState('');
  const [filterCountryFacility, setCountryFacility] = useState([]);
  const [choosenYear, setChooseYear] = useState();
  
  const productionList = useSelector(
    (state) => state.productionData.productionDataList.data
  );
  const productionListStatus = useSelector(
    (state) => state.productionData.productionDataList.status
  );

  const countryData = useSelector(state => state.listings.getOrganizationCountry.data.country_data);
  const countryFacilityData = useSelector(state => state.listings.getOrganizationCountry.data.country_facility_data);
  
  const transformObject = (obj) => {
    return Object.entries(obj).map(([outerKey, innerObj]) => {
        // Iterate over each key-value pair in the inner object
        return Object.entries(innerObj).map(([innerKey, innerValue]) => {
            return {
                key: innerValue,
                value: innerKey
            };
        });
    }).flat(); // 
};

useEffect(() => {
    setCountryFacility(transformObject(countryFacilityData))
  }, [dispatch, countryFacilityData]);

  useEffect(() => {
    // setCountryFacility(transformObject(countryFacilityData))
    choosenCountry && setCountryFacility(Object.entries(countryFacilityData[choosenCountry]).map(([innerKey, innerValue]) => {
        return {
            key: innerValue,
            value: innerKey
        };
    }));
    
  }, [dispatch, choosenCountry]);


  const countryList = Object.keys(countryData).map((item) => ({
    key: countryData[item],
    value: item,
  }));

  useEffect(() => {
    dispatch(getProductionDataList(choosenYear?choosenYear:0,choosenCountry?choosenCountry:'',choosenFacility?choosenFacility:''))
  }, [dispatch, choosenCountry, choosenFacility, choosenYear]);

  

  return (
    <DashboardLayout>
      <Container className={classes.container}>
      <div className={classes.backContainer} >
            <div className={classes.backButtonDiv} onClick={()=>{navigate(-1)}}>
            <ArrowBackIosIcon />
            Back
            </div>
            <div className={classes.templateButtonDiv}>
                <CeroButton
                buttonText="Add Production Data"
                className={classes.bulkButton}
                onClick={()=>navigate('/production-data-add')
                }
                />
                </div>
        </div>
        <div className={classes.topContainer}>
          <Grid spacing={2} className={classes.filterContainer}>
            <Grid item md={4} style={{ width: "100%"}}>
          <CeroSelect
              classes={{ container: classes.autoComplete,  root: classes.textField }}
              id="country"
              label="Country"
              fullWidth
              selectedValue={choosenCountry}
              onChange={(e) => setCountry(e.target.value)}              
              options={countryList}
            />
            </Grid>
            <Grid item md={4} style={{ width: "100%"}}>
            <CeroSelect
              classes={{ container: classes.selectContainer,  root: classes.textField }}
              id="facility"
              name="facility"
              label="Facility"
              fullWidth
              options={filterCountryFacility}
              selectedValue={choosenFacility}
              onChange={(e)=>setFacility(e.target.value)}
            />
            </Grid>
            <Grid item md={4} style={{ width: "100%"}}>
          <CeroSelect
              classes={{ container: classes.autoComplete,  root: classes.textField }}
              id="year"
              label="Year"
              fullWidth
              selectedValue={choosenYear}
              onChange={(e) => setChooseYear(e.target.value)}              
              options={sampleYear()}
            />
            </Grid>
          </Grid>
            
        </div>
        {productionListStatus === STATUS.SUCCESS ? (
          <Container className={classes.tableContainer}>
            <CeroTable
                columns={ProductionListColumns}
                hasMore={false}
                loading={false}
                data={productionList}
                onSelectRow={()=>console.log('')
                }
            />
          </Container>
        ) : (
          <Box className={classes.loader}>
            <Typography variant="h7" component="span">
              {productionListStatus === STATUS.RUNNING ? (
                <CircularProgress />
              ) : productionListStatus === STATUS.ERROR ? (
                "We couldn't process your request. Please try again later"
              ) : (
                ""
              )}
            </Typography>
          </Box>
        )}
      </Container>
    </DashboardLayout>
  );
};

export default ProductionDataList;
