import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useFormik, FieldArray, FormikProvider } from "formik";
import CeroButton from "../../../../components/CeroButton";
import useStyles from "./styles";
import CeroInput from "../../../../components/CeroInput";
import AddIcon from "@mui/icons-material/Add";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { sendQuestionnaires } from "../../../../redux/actions";
import { STATUS } from "../../../../redux/constants";
import CeroAutoComplete from "../../../../components/CeroAutoComplete";
import CeroPopUp from "../../../../components/CeroPopUp";
import ViewQuestions from "../ViewQuestions";
import { getMaterialityIndustries } from "../../../../redux/actions/materiality";
import { sendSupplierQuestions } from "../../../../redux/actions";
import clsx from "clsx";
import { useSnackbar } from "notistack";
import { resetSupplierScreeningStatus } from "../../../../redux/actions/supplierScreening";
import CloseIcon from "@mui/icons-material/Close";

const validationSchema = Yup.object().shape({
  users: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required("Name is required"),
      email: Yup.string()
        .email("Invalid email format")
        .required("Email is required"),
      industry: Yup.string().nullable(),
    })
  ),
});

const SendQuestions = ({ setOpen, sendQuestionnairesStatus }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [fields, setFields] = useState([{ name: "", email: "", industry: ""}]);
  const [viewQuestionPopup, setViewQuestionPopup] = useState(false);
  const [currentIndustry, setCurrentIndustry] = useState("");
  
  const industryData = useSelector((state) => state.materiality.getMaterialityIndustries.data) || [];
  const sendQuestionsStatus = useSelector((state) => state.supplierScreening.sendSupplierQuestions.status);
  const sendQuestionsData = useSelector((state) => state.supplierScreening.sendSupplierQuestions);

  const formik = useFormik({
    initialValues: {
      users: fields,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log("values in submit", values.users);
      
      const payload = {
        sender_list: values.users.map(user => ({
          name: user.name,
          email: user.email,
          industry_type: user.industry
        }))
      };   
      dispatch(sendSupplierQuestions(payload));
      formik.resetForm();
    },
  });
  console.log("values in formik", formik.values.users);

  // useEffect(() => {
  //   if (sendQuestionsStatus === STATUS.SUCCESS) {
  //     enqueueSnackbar(sendQuestionsData?.data?.message, {
  //       variant: "success",
  //     });
  //     dispatch(resetSupplierScreeningStatus())
  //   } else if (sendQuestionsStatus === STATUS.ERROR) {
  //     enqueueSnackbar(
  //       sendQuestionsData.message ||
  //         "We couldn't process your request. Please try again later.",
  //       { variant: "error" }
  //     );
  //     dispatch(resetSupplierScreeningStatus())
  //   }
  // }, [sendQuestionsStatus, dispatch, enqueueSnackbar]);

  const handleAddMore = () => {
    setFields([...fields, { name: "", email: "", industry: currentIndustry }]);
    formik.setFieldValue("users", [
      ...formik.values.users,
      { name: "", email: "", industry: currentIndustry},
    ]);
    
  };
  
  const canAddMore =
    formik.values.users.every(item=> item.name!=="" && item.email!=="" && item.industry!=="")

  const handleSubmit = () => {
    formik.handleSubmit();
    setOpen(false)
  };

  useEffect(() => {
    dispatch(getMaterialityIndustries());
  }, [dispatch]);

  useEffect(() => {
    if (sendQuestionnairesStatus === STATUS.SUCCESS) {
      formik.resetForm();
    }
  }, [sendQuestionnairesStatus]);

  const handleCancel = () => {
    setOpen(false);
    formik.resetForm();
  };

  const industryList = Array.isArray(industryData)
    ? industryData.map((item) => ({
        id: item.id,
        label: item.industry_type,
      }))
    : [];

  const handleViewQuestionnaireClick = (industryId) => {
    setViewQuestionPopup(true); 
  };

  const handleIndustryChange = (indx, value) => {
    formik.setFieldValue(
        `users[${indx}].industry`,
        value?.id || ""
      )
    setCurrentIndustry(value?.id || "");
    
  }

  const [openQuestionsForm, setOpenQuestionsForm] = useState(false);
  const createQuestionStatus = useSelector((state) => state.supplierScreening.createSupplierQuestions.status) 
  const  companyName = "company"

  return (
    <Box className={classes.popUp}>
      <CloseIcon onClick={handleCancel} className={classes.closeIcon} />
      <Typography variant="body1" className={classes.popupTitle}>
        Send Questionnaire to Suppliers
      </Typography>
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <FieldArray name="users">
            {() => (
              <Grid
                display={"flex"}
                flexDirection="column"
                mt={4}
                gap={2}
                justifyContent="flex-start"
                className={classes.eachIndustryBox}
              >
                <Typography
                  display={"flex"}
                  fontSize={14}
                  fontWeight={500}
                  variant="h3"
                >
                  Enter Name, Email ID & Selected Industry:
                </Typography>
                {formik.values.users.map((stakeholder, index) => (
                  <Box
                    key={index}
                    display={"flex"}
                    flexDirection="column"
                    gap={1}
                  >
                    <Grid container spacing={2} display={"flex"}>
                      <Grid item xs={6} style={{ paddingTop: index !== 0 ? '0px' : '8px' }}>
                      {index==0 && 
                      <CeroAutoComplete
                          classes={{
                            container: classes.autoComplete,
                            root: classes.root,
                            option: classes.autoCompleteOption,
                          }}
                          id={`industry-${index}`}
                          label="Supplier Industry"
                          fullWidth
                          value={
                            industryList.find(
                              (item) =>
                                item.id === formik.values.users[index].industry
                            )?.label || ""
                          }
                          onChange={(e, newValue) =>
                            // formik.setFieldValue(
                            //   `users[${index}].industry`,
                            //   newValue?.id || ""
                            // )
                            handleIndustryChange(index, newValue)
                          }
                          options={industryList || []}
                        />}
                        <CeroInput
                          required
                          id={`name-${index}`}
                          name={`users[${index}].name`}
                          label="Name"
                          value={formik.values.users[index].name}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={
                            formik.touched.users &&
                            formik.touched.users[index]?.name &&
                            Boolean(
                              formik.errors.users &&
                                formik.errors.users[index]?.name
                            )
                          }
                          helperText={
                            formik.touched.users &&
                            formik.touched.users[index]?.name &&
                            formik.errors.users &&
                            formik.errors.users[index]?.name
                          }
                          classes={{
                            container: index===0 ? classes.input : classes.inputWithoutMargin,
                          }}
                        />
                        
                      </Grid>
                      <Grid item xs={6} style={{ paddingTop: index !== 0 ? '0px' : '8px' }}>
                        {index==0 && 
                      <div className={classes.viewButton}>
                          <CeroButton
                            buttonText="View Questionnaire"
                            variant="contained"
                            className={clsx(classes.button, classes?.buttonPrimary)}
                            onClick={() =>
                              handleViewQuestionnaireClick(
                                formik.values.users[index].industry
                              )
                            }
                            disabled={
                              !formik.values.users[index].industry 
                            }
                          />
                        </div>
                        }
                        <CeroInput
                          required
                          id={`email-${index}`}
                          name={`users[${index}].email`}
                          label="Email"
                          value={formik.values.users[index].email}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={
                            formik.touched.users &&
                            formik.touched.users[index]?.email &&
                            Boolean(
                              formik.errors.users &&
                                formik.errors.users[index]?.email
                            )
                          }
                          helperText={
                            formik.touched.users &&
                            formik.touched.users[index]?.email &&
                            formik.errors.users &&
                            formik.errors.users[index]?.email
                          }
                          classes={{
                            container: index===0 ? classes.input : classes.inputWithoutMargin,
                          }}
                        />
                        
                      </Grid>
                    </Grid>
                  </Box>
                ))}
                <Box
                  display="flex"
                  paddingY={2}
                  paddingLeft={2}
                  gap={1}
                  onClick={canAddMore ? handleAddMore : null}
                  style={{
                    cursor: canAddMore ? "pointer" : "not-allowed",
                    color: canAddMore ? "blue" : "gray",
                  }}
                >
                  <AddIcon />
                  <Typography>Add Name and Email</Typography>
                </Box>
              </Grid>
            )}
          </FieldArray>
          <div className={classes.buttonContainerReview}>
            <CeroButton
              className={classes.buttonPrimary}
              variant="contained"
              type="submit"
              buttonText="Send Email"
              onClick={handleSubmit}
              disabled={!(formik.isValid && formik.dirty &&  formik.values.users.every(
                (user) => user.name && user.email && user.industry
              ))}
            />
            <CeroButton
              className={classes.buttonSecondary}
              variant="outlined"
              onClick={handleCancel}
              buttonText="Cancel"
            />
          </div>
        </form>
      </FormikProvider>
      <CeroPopUp
        primaryPopUp={{
          open: viewQuestionPopup,
          onClose: () => setViewQuestionPopup(false),
          content: ViewQuestions({
            openQuestionsForm,
            setOpenQuestionsForm,
            createQuestionStatus,
            companyName,
            currentIndustry
          }),
          header: { title: "" },
        }}
        classes={{ dialog: classes.popUpdialog }}
        showCloseButton={true}
      />
    </Box>
  );
};

export default SendQuestions;
