import { createActions } from "redux-actions";

import { ActionTypes } from "../constants/actions";

export const {
  addVision,
  getVision,
  addMission,
  editMission,
  getMission,
  resetGoals,
  getInitiativeDetails,
  addInitiative,
  listInitiative,
  addTasks,
  getTasksList,
  resetAddInitiativeStatus,
  resetAddTaskStatus,
  saveTaskTracking,
  addTargetActual,
  getTrackingCycleAssessmentPeriod,
  editTask
} = createActions({
  [ActionTypes.ADD_VISION]: (payload) => payload,
  [ActionTypes.GET_VISION]: () => ({}),
  [ActionTypes.ADD_MISSION]: (mission, country) => ({ mission, country }),
  [ActionTypes.EDIT_MISSION]: (missionId, mission, country) => ({
    missionId,
    mission,
    country,
  }),
  [ActionTypes.GET_MISSION]: (country) => ({ country }),
  [ActionTypes.RESET_GOALS]: () => ({}),
  [ActionTypes.ADD_INITIATIVE]: (payload) => payload,
  [ActionTypes.LIST_INITIATIVE]: (year, country, facility) => ({
    year,
    country,
    facility,
  }),
  [ActionTypes.ADD_TASKS]: (payload) => payload,
  [ActionTypes.GET_TASKS_LIST]: (initiativeId) => ({ initiativeId }),
  [ActionTypes.GET_INITIATIVE_DETAILS]: (initiativeId) => ({ initiativeId }),
  [ActionTypes.RESET_ADD_INITIATIVE_STATUS]: () => ({}),
  [ActionTypes.RESET_ADD_TASK_STATUS]: () => ({}),
  [ActionTypes.SAVE_TASK_TRACKING]: (Id, taskDetails) => ({ Id, taskDetails }),
  [ActionTypes.ADD_TARGET_ACTUAL]: (values) => ({ values }),
  [ActionTypes.GET_TRACKING_CYCLE_ASSESSMENT_PERIOD]: (values) => ({ values }),
  [ActionTypes.EDIT_TASK]: (Id, name, period, year) => ({ Id, name, period, year }),
});

