import { Container, Box, Typography } from "@mui/material";
import CeroTable from "../../../../components/CeroTable";
import { months } from "../../../../constants";
import dayjs from "dayjs";

import useStyles from "./styles";

export const approvalMonthlySummaryColumns = [
  {
    columnKey: "assessment_year",
    columnId: "assessment_year",
    columnHeader: "Assessment Year",
  },
  {
    columnKey: "period",
    columnId: "period",
    columnHeader: "Period",
  },
  {
    columnKey: "facility_name",
    columnId: "facility_name",
    columnHeader: "Facility name",
  },
  {
    columnKey: "program",
    columnId: "program",
    columnHeader: "Program name",
  },
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
];

const MonthlySummaryTable = (props) => {
  const classes = useStyles();
  const { onSelectApprovalSummaryData, summaryData } = props;


  const getStatus = (status) => {
    console.log('status', status)
    if (status === 'approved') {
        return <Typography className={classes.approved}>Approved</Typography>;
    } else if (status === 'assigned') {
        return <Typography className={classes.assigned}>Pending Approval</Typography>;
    } else if (status === 'audited') {
        return <Typography className={classes.audited}>Audited</Typography>;
    } else if (status === 'review') {
      return <Typography className={classes.review}>Review</Typography>;
    }else if (status === 'reported') {
        return <Typography className={classes.reported}>Reported</Typography>;
    } else if (status === 'submitted') {
      return <Typography className={classes.submitted}>Pending Approval</Typography>;
    } else if (status === 'added') {
      return <Typography className={classes.added}>Added</Typography>; 
    } else if (status === 'verified') {
      return <Typography className={classes.verified}>Verified</Typography>; 
    } else if (status === 'In Progress') {
      return <Typography className={classes.submitted}>In Progress</Typography>;
    } else if (status === 'pending approval') {
      return <Typography className={classes.submitted}>Pending Approval</Typography>;
    } else {
        return <Typography className={classes.default}>{status}</Typography>;
    }
};

  const getSummaryData = () =>
    summaryData?.map((item) => ({
      ...item,
      status: getStatus(item.status.toLowerCase()),
    }));

  return (
    <Container className={classes.container}>
      <CeroTable
        columns={approvalMonthlySummaryColumns}
        data={getSummaryData()}
        hasMore={false}
        loading={false}
        onSelectRow={onSelectApprovalSummaryData}
        useExtraStyles={true}
        useModifiedHeader={true}
        classes={{ tableContainer: classes.tableContainer }}
      />
    </Container>
  );
};

export default MonthlySummaryTable;
