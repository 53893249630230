import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  Typography,
} from "@mui/material";
import DashboardLayout from "../../../layouts/DashboardLayout";
import CeroButton from "../../../components/CeroButton";
import { useNavigate } from "react-router-dom";
import useStyles from "./styles";
import CeroPopUp from "../../../components/CeroPopUp";
import SendQuestions from "./SendQuestions";
import CeroInput from "../../../components/CeroInput";
import { getGenericQuestions } from "../../../redux/actions/supplierScreening";
import CeroTable from "../../../components/CeroTable";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CeroAutoComplete from "../../../components/CeroAutoComplete";
import { getCountryList, getStates } from "../../../redux/actions";
import CeroPhoneInput from "../../../components/CeroPhoneInput";

const SupplierScreeningSurvey = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [selectedValues, setSelectedValues] = useState({});
  const dispatch = useDispatch();
  const [state, setState] = useState('');
  const [country, setcountry] = useState('');
  const [companyNumber, setCompanyNumber] = useState('');
  const [contactNumber, setContactNumber] = useState('');

  const sendQuestionnairesStatus = useSelector(
    (state) => state.supplierScreening.getGenericQuestions.status
  );
  
  const generalQuestionsList = useSelector((state) => {
    const data = state.supplierScreening?.getGenericQuestions?.data;
    return Array.isArray(data) ? data : [];
  });
  useEffect(() => {
    dispatch(getGenericQuestions());
    dispatch(getCountryList());
  }, [dispatch]);

  useEffect(()=>{
    dispatch(getStates(country.id))
  }, [country])

  const handleCheckboxChange = (event, questionId) => {
    const value = event.target.value;
    setSelectedValues((prevValues) => {
      const questionValues = prevValues[questionId] || [];
      if (questionValues.includes(value)) {
        return {
          ...prevValues,
          [questionId]: questionValues.filter((item) => item !== value),
        };
      } else {
        return {
          ...prevValues,
          [questionId]: [...questionValues, value],
        };
      }
    });
  };

  const groupedQuestions = generalQuestionsList?.reduce((acc, question) => {
    const section = question.section || "Uncategorized";
    if (!acc[section]) {
      acc[section] = [];
    }
    acc[section].push(question);
    return acc;
  }, {});
  
  function reorderLists(data) {
    Object.keys(data).forEach((key) => {
      const list = data[key];
      const reorderedList = [];
      const itemMap = new Map();
  
      // Create a map of items based on their `_id`
      list.forEach((item) => {
        itemMap.set(item.id, item);
      });
  
      // Iterate through the list and reorder dynamically
      list.forEach((item) => {
        if (!reorderedList.includes(itemMap.get(item.id))) {
          reorderedList.push(item); // Add the main item first
        }
        // Find and add all items with matching `question_id`
        const relatedItems = list.filter((related) => related.question_id === item.id);
        relatedItems.forEach((relatedItem) => {
          if (!reorderedList.includes(relatedItem)) {
            reorderedList.push(relatedItem);
          }
        });
      });
  
      // Add any remaining items that weren't matched
      const remainingItems = list.filter((item) => !reorderedList.includes(item));
      reorderedList.push(...remainingItems);
  
      // Update the original list with the reordered one
      data[key] = reorderedList;
    });
  
    return data;
  }
  
  let questionNumber = 2;

  const countryListData = useSelector(
    (state) => state.listings.countryList.data
  );
  const countryList = countryListData.map((item) => ({
    id: item.code,
    label: item.name,
  }));
  const stateList = useSelector((state) => state.listings.getStates.data);
  const stateNames = Object.values(stateList).map(
    (stateObj) => Object.values(stateObj)[0]
  );
  const renderSupplierInfo = (infoQuestions) =>{
    delete groupedQuestions['Company Profile']
    var qtIndex = 1;
    const nameCont = infoQuestions?.filter(item=>['company name', 'company phone number' ].includes(item.question.toLowerCase()))
    const addrCntct = infoQuestions?.filter(item=>['company address', 'contact' ].includes(item.question.toLowerCase()))
    const others = infoQuestions?.filter(item=>['email id', 'designation', 'contact number' ].includes(item.question.toLowerCase()))
    
    return(
      <>
    <Typography
                        fontSize={18}
                        fontWeight={500}
                        variant="body1"
                        className={classes.mainQuestion}
                      >
                        {`1. Supplier information:`}
                      </Typography>
                      <Box className={classes.supInfo}>
                      {nameCont?.map((it,index)=>
                        <Grid key={it.id} paddingLeft={6} className={classes.supInfoHalfContent}>
                        <Typography className={classes.subQuestion}>
                          {`${String.fromCharCode(96 + qtIndex+index)}. ${it.question}`}
                        </Typography>
                        <Box>
                          {it.question.toLowerCase().includes('phone')?
                          <CeroPhoneInput
                          required
                          fullWidth
                          label="Company Number"
                          name="company phone"
                          value={companyNumber}
                          disabled={true}
                          onChange={(value) => setCompanyNumber(value)}
                          // onBlur={userDetailsForm.handleBlur}
                          // error={userDetailsForm.touched.phone && userDetailsForm.errors.phone}
                          classes={{ container: classes.phoneInput }}
                        />: 
                          <CeroInput
                            classes={{container : classes.inputContainer}}
                            rows={2}
                            placeholder={"Please type your Answer"}
                            id={`answer-${it.id}-${it.question}`}
                            name={`answer-${it.id}-${it.question}`}
                            label={`Enter ${it.question}`}
                            multiline
                            disabled
                          />}
                        </Box>
                        </Grid>
                      )}
                      {addrCntct?.map((it,index)=>
                        <Grid key={it.id} paddingLeft={6} className={classes.supInfoFullContent}>
                        <Typography className={classes.subQuestion}>
                          {`${String.fromCharCode(96 + (nameCont?.length ? nameCont?.length:0) + qtIndex+index)}. ${it.question}`}
                        </Typography>
                        <Box className={classes.addressInner}>
                          {Object.entries(it?.sub_fields).map(([key,value])=>
                          key.includes('Country')? 
                          <CeroAutoComplete
                            required
                            id="country"
                            name="country"
                            label="Country"
                            fullWidth
                            options={countryList}
                            value={country}
                            onChange={(e, value) => {
                              setcountry(value);
                              setState('');
                            }}
                            isOptionEqualToValue={(option, value) => option.label === value}
                            disabled={false}
                            placeholder={key}
                          />:key.includes('State')? 
                          <CeroAutoComplete
                            required = {stateNames.length === 0 ? false : true}
                            id="state"
                            name="state"
                            label={stateNames.length === 0 ? " " : "State"}
                            fullWidth
                            options={stateNames.map((state) => ({
                              label: state,
                              value: state,
                            }))}
                            value={state}
                            onChange={(e, value) => setState(value)}
                            isOptionEqualToValue={(option, value) => option.label === value}
                            disabled={stateNames.length === 0}
                          />:
                          <CeroInput
                            classes={{container : key.toLowerCase().includes('postal') ?classes.inputContainerFreeMargin :  classes.inputContainer}}
                            rows={2}
                            placeholder={key}
                            id={`answer-${it.id}-${it.question}`}
                            name={`answer-${it.id}-${it.question}`}
                            label={`Enter ${key}`}
                            multiline
                            disabled
                          />)}
                        </Box>
                        </Grid>
                      )}
                      {others?.map((it,index)=>
                        <Grid key={it.id} paddingLeft={6} className={classes.supInfoHalfContent}>
                        <Typography className={classes.subQuestion}>
                          {`${String.fromCharCode(96 + (nameCont?.length ? nameCont?.length:0) + (addrCntct?.length ? nameCont?.length:0) + qtIndex+index)}. ${it.question}`}
                        </Typography>
                        <Box>
                        {it.question.toLowerCase().includes('number')?
                          <CeroPhoneInput
                          required
                          fullWidth
                          label="Contact Number"
                          name="phone"
                          value={contactNumber}
                          onChange={(value) => setContactNumber(value)}
                          // onBlur={userDetailsForm.handleBlur}
                          // error={userDetailsForm.touched.phone && userDetailsForm.errors.phone}
                          classes={{ container: classes.phoneInput }}
                          disabled={true}
                        />: 
                          <CeroInput
                            classes={{container : classes.inputContainer}}
                            rows={2}
                            placeholder={"Please type your Answers"}
                            id={`answer-${it.id}-${it.question}`}
                            name={`answer-${it.id}-${it.question}`}
                            label={`Enter ${it.question}`}
                            multiline
                            disabled
                          />}
                        </Box>
                        </Grid>
                      )}
                      </Box>
                      </>

  )}
  return (
    <DashboardLayout>
      <Container className={classes.container}>
        <div className={classes.buttonContainer}>
          <CeroButton
            buttonText="Send Questionnaire to Suppliers"
            variant="contained"
            className={classes.buttonPrimary}
            onClick={() => setOpen(true)}
          />
        </div>
        <Box className={classes.box}>
          <Typography fontWeight={600}>
            SUPPLIER ASSESSMENT GENERAL QUESTIONNAIRE
          </Typography>
          {renderSupplierInfo(groupedQuestions['Company Profile'])}
          {Object.entries(reorderLists(groupedQuestions))?.map(([sectionTitle, questions]) => (
            <Box key={sectionTitle} mb={4}>
              {questions?.map((question) => {
                if (question.question_id === null) {
                  const currentQuestionNumber = questionNumber;
                  questionNumber += 1;
                  return (
                    <Grid
                      key={question.id}
                      className={
                        question.question_type === "text"
                          ? classes.inputGrid
                          : classes.gridItem
                      }
                    >
                      <Typography
                        fontSize={18}
                        fontWeight={500}
                        variant="body1"
                        className={classes.mainQuestion}
                      >
                        {`${currentQuestionNumber}. ${question.question}`}
                      </Typography>
                      <Box className={classes.layout} display="flex" flexDirection="column" paddingLeft={6}>
                        {(question.question_type === "choose_from_option" ||
                          question.question_type === "choose_from_topic" ||
                          question.question_type === "multiselect_check_box") &&
                          question.options.map((option, idx) => (
                            <FormControlLabel
                              key={idx}
                              control={
                                <Checkbox
                                  className={classes.selectCheckbox}
                                  checked={
                                    selectedValues[question.id]?.includes(option) || false
                                  }
                                  onChange={(e) =>
                                    handleCheckboxChange(e, question.id)
                                  }
                                  value={option}
                                  sx={{
                                    color: "#5A404E !important",
                                    "&.Mui-checked": {
                                      color: "#8C2148 !important",
                                    },
                                  }}
                                  disabled
                                />
                              }
                              label={option}
                              className={classes.label}
                            />
                          ))}
                        {question.question_type === "text" && (
                          <Box>
                            <CeroInput
                              classes={{ container: classes.inputContainer }}
                              rows={3}
                              placeholder={"Please type your Answer"}
                              id={`answer-${question.id}`}
                              name={`answer-${question.id}`}
                              label="Please type your Answer"
                              multiline
                              disabled
                            />
                          </Box>
                        )}
                      </Box>
                    </Grid>
                  );
                }
                const subQuestionLetter = String.fromCharCode(
                  96 + generalQuestionsList
                    .filter((q) => q.question_id === question.question_id)
                    .indexOf(question) + 1
                );
                return (
                  <Grid key={question.id} paddingLeft={6}>
                    <Typography className={classes.subQuestion}>
                      {`${subQuestionLetter}. ${question.question}`}
                    </Typography>
                    {question.options.map((option, idx) => (
                      <FormControlLabel
                        key={idx}
                        control={
                          <Checkbox
                            className={classes.selectCheckbox}
                            checked={
                              selectedValues[question.id]?.includes(option) || false
                            }
                            onChange={(e) =>
                              handleCheckboxChange(e, question.id)
                            }
                            value={option}
                            sx={{
                              color: "#5A404E !important",
                              "&.Mui-checked": {
                                color: "#8C2148 !important",
                              },
                            }}
                            disabled
                          />
                        }
                        label={option}
                        className={classes.label}
                      />
                    ))}
                    {question.question_type === "text" && (
                        <Box>
                          <CeroInput
                            classes={{container : classes.inputContainer}}
                            rows={2}
                            placeholder={"Please type your Answer"}
                            id={`answer-${question.id}`}
                            name={`answer-${question.id}`}
                            label="Please type your Answer"
                            multiline
                            disabled
                          />
                        </Box>
                      )}
                      {question.question_type === "upload" && (
                        <Box className={classes.upload}>
                          <Button
                            component="label"
                            role={undefined}
                            variant="contained"
                            tabIndex={-1}
                            disabled
                            className={classes.buttonPrimary}
                            startIcon={<CloudUploadIcon />}
                          >
                            Upload files
                          </Button>
                        </Box>
                      )}
                  </Grid>
                );
              })}
            </Box>
          ))}
        </Box>
        <CeroPopUp
          primaryPopUp={{
            open: open,
            onClose: () => setOpen(false),
            content: SendQuestions({ setOpen, sendQuestionnairesStatus }),
            header: { title: "" },
          }}
          classes={{ dialog: classes.popUpdialog }}
        />
      </Container>
    </DashboardLayout>
  );
};

export default SupplierScreeningSurvey;
