import React, { useEffect, useState } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import useStyles from "./styles";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import ReactCountryFlag from "react-country-flag";
import DashboardLayout from "../../../../layouts/DashboardLayout";
import {
  getIotData,
  getOffsetDetails,
  refreshIotData,
  resetCarbonOffset,
} from "../../../../redux/actions";
import CeroButton from "../../../../components/CeroButton";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import DataTable from "./DataTable";
import { useSnackbar } from "notistack";
import { STATUS } from "../../../../redux/constants";
import CeroAutoComplete from "../../../../components/CeroAutoComplete";
import { sampleYear } from "../../../../constants";
import { getOffsetIotDataDetails } from "../../../../redux/actions/approval";
import CeroInfoPair from "../../../../components/CeroInfoPair";
import { capitalizeFirstLetterOfEachWord } from "../../../../services/utilityService";
import PdfFileIconWithDownload from "../../../../assets/images/Download";

const MonthlyProgramDetails = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  // const [year, setYear] = useState(0);
  const location = useLocation();
  const { year, period } = location.state;

  const projectDetails = useSelector(
    (state) => state.carbonOffset.getOffsetDetails.data
  );

  const projectDetailsCard = useSelector(
    (state) => state.carbonOffset.getOffsetDetails.status
  );

  const iotDetails = useSelector((state) => state.carbonOffset.getIotData.data);
  const getOffset = useSelector(
    (state) => state.approval.getOffsetIotDataDetails.data
  );
  const updatedStatus = useSelector(
    (state) => state.carbonOffset.updateIotData
  );
  const refreshedData = useSelector(
    (state) => state.carbonOffset.refreshIotData
  );
  const yearList = sampleYear().map((item) => ({
    id: item.key,
    label: item.value,
  }));

  useEffect(() => {
    dispatch(getIotData(id, year));
    dispatch(getOffsetIotDataDetails(id, year, period));
  }, [dispatch, id, year, updatedStatus]);

  useEffect(() => {
    if (refreshedData?.status === STATUS.SUCCESS) {
      enqueueSnackbar("Data fetched from IoT successfully!", {
        variant: "success",
        autoHideDuration: 3000,
      });
      dispatch(getIotData(id, year));
      dispatch(resetCarbonOffset());
    } else if (refreshedData?.status === STATUS.ERROR) {
      enqueueSnackbar(
        refreshedData?.message
          ? refreshedData?.message
          : "We couldn't process your request. Please try again later.",
        { variant: "error", autoHideDuration: 3000 }
      );
      dispatch(resetCarbonOffset());
    }
  }, [refreshedData, enqueueSnackbar, dispatch]);

  useEffect(() => {
    id && dispatch(getOffsetDetails(id));
  }, [dispatch, id]);

  const refreshData = () => {
    id && dispatch(refreshIotData(id));
  };
  const onChangeFilter = (selectedValue) => {
    // setYear(selectedValue.id);
  };

  const offsetStatus = getOffset?.status?.includes('_') ? getOffset?.status?.split('_')?.join(' ') : getOffset?.status

  return (
    <DashboardLayout>
      <div
        className={classes.backContainer}
        onClick={() => {
          navigate(-1);
        }}
      >
        <ArrowBackIosIcon />
        Back
      </div>
      {projectDetailsCard === STATUS.RUNNING ? (
        <Box className={classes.loader}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Box className={classes.boxContainer}>
            <Grid className={classes.titleGrid}>
              <Grid item>
                <Typography variant="h6">{projectDetails?.name}</Typography>
                <div className={classes.location}>
                  <ReactCountryFlag
                    countryCode={projectDetails?.country_code}
                    svg
                    style={{
                      width: "2em",
                      height: "1.8em",
                      marginRight: "0.8em",
                    }}
                  />
                  <Typography variant="subtitle1">
                    {projectDetails?.location}
                  </Typography>
                </div>
              </Grid>

              <Grid item display="flex">
                <Typography>Status : &nbsp; </Typography>
                <Typography
                  className={`${classes.infoContainer} ${
                    getOffset?.status === "In Progress"
                      ? classes.Inprogress
                      : classes[getOffset?.status]
                  }`}
                >
                  {capitalizeFirstLetterOfEachWord(offsetStatus)}
                </Typography>
              </Grid>
            </Grid>
            <Typography className={classes.contentBox}>
              {projectDetails?.description}
            </Typography>
            <Grid spacing={5} className={classes.gridContainer}>
              <Grid item xs={4}>
                <Typography className={classes.gridItemTitle}>
                  Program Type
                </Typography>
                <Typography className={classes.gridItem}>
                  {projectDetails?.program_type}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography className={classes.gridItemTitle}>
                  Program
                </Typography>
                <Typography className={classes.gridItem}>
                  {projectDetails?.program_name}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography className={classes.gridItemTitle}>
                  Start year
                </Typography>
                <Typography className={classes.gridItem}>
                  {projectDetails?.start_year}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography className={classes.gridItemTitle}>
                  Installed Capacity (kW)
                </Typography>
                <Typography className={classes.gridItem}>
                  {
                    projectDetails?.program_specific_data?.find(
                      (data) => data.data_name === "Installed capacity (KW)"
                    )?.value
                  }
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography className={classes.gridItemTitle}>
                  No. of Panels installed
                </Typography>
                <Typography className={classes.gridItem}>
                  {
                    projectDetails?.program_specific_data?.find(
                      (data) => data.data_name === "number of panels"
                    )?.value
                  }
                </Typography>
              </Grid>
            </Grid>
          </Box>
          {projectDetails?.attachment_url?.length > 0 && <Box display='flex' justifyContent='flex-end' paddingRight={5.5} alignItems='center'>
            <Typography className={classes.attachmentTitle}>Attached Documents</Typography>
            {projectDetails?.attachment_url?.map((url) => (
              <a
                href={url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <PdfFileIconWithDownload />
              </a>
            ))}
          </Box>}
          <div className={classes.tableTitle}>
            <Typography variant="h6" paddingBottom={8} paddingTop={3}>
              Data collected from IoT Device
            </Typography>
            <DataTable
              programId={id}
              iotDetails={getOffset}
              updatedStatus={updatedStatus}
            />
          </div>
        </>
      )}
    </DashboardLayout>
  );
};

export default MonthlyProgramDetails;
