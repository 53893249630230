import * as yup from 'yup';

export const addProductionDataValidation = yup.object({
  facility: yup.string('Facility is required').required('Facility is required'),
  month: yup.string('Month is required').required('Month is required'),
  year: yup.string('Year is required').required('Year is required'),
  country: yup.string('Country is required').required('Country is required'),
  unit: yup.string('unit is required').required('unit is required'),
  produce: yup.string('produce is required').required('produce is required'),
  value: yup.string('value is required').required('value is required'),
  
});
