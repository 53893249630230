import { createActions } from 'redux-actions';

import { ActionTypes } from '../constants/actions';
import { DataArrayRounded } from '@mui/icons-material';

export const {
    getApprovalDetails,
    getApprovalSummary,
    getApprovalMonthlyDetails,
    getApprovalMonthlySummary,
    submitApproval,
    requestApproval,
    approveRequest,
    resetApprovalData,
    resetDataApproved,
    getApprovalTopicStatus,
    roleBasedApproval,
    submitMonthlyApproval,
    assignMonthlyApproval,
    approveMonthlyApproval,
    pendingApprovals,
    addReviewComments,
    resetApprovalSummary,
    resetApprovalDetails,
    updateAuditTypes,
    getApprovalEmission,
    getTopicBasedApproval,
    assignToSubmit,
    pendingApprovalEmission,
    getApprovedData,
    getOffsetIotDataDetails,
    getOverallApprovalStatus,
    getOverviewApprovalStatus,
    getOverallRequestApprovalStatus,
    reviewOffsetData,
    approveOffsetData,
    submitOffsetData,
    getOffsetApprovalStatus,
    resetReviewOffsetStatus,
    resetApproveOffsetStatus,
    resetSubmitOffsetStatus
} = createActions({
    [ActionTypes.GET_APPROVAL_DETAILS]: (year, facility, auditId) => ({year, facility, auditId}),
    [ActionTypes.GET_APPROVAL_SUMMARY]: (year, facility, auditId) => ({year, facility, auditId}),
    [ActionTypes.GET_APPROVAL_MONTHLY_DETAILS]: (id, year, month, facility) => ({year, facility, id, month}),
    [ActionTypes.GET_APPROVAL_MONTHLY_SUMMARY]: (id, year, month, facility) => ({year, facility, id, month}),
    [ActionTypes.SUBMIT_APPROVAL]: (statusId, userId) => ({statusId, userId}),
    [ActionTypes.REQUEST_APPROVAL]: (statusId, userId) => ({statusId, userId}),
    [ActionTypes.APPROVE_REQUEST]: (statusId, comment) => ({statusId, comment}),
    [ActionTypes.RESET_APPROVAL_DATA]: () => ({}),
    [ActionTypes.RESET_DATA_APPROVED]: () => ({}),
    [ActionTypes.GET_APPROVAL_TOPIC_STATUS]: (statusId) => ({statusId}),
    [ActionTypes.ROLE_BASED_APPROVAL]: (statusId, emissionType, categoryType) => ({statusId, emissionType, categoryType}),
    [ActionTypes.SUBMIT_MONTHLY_APPROVAL]: (statusId, categoryType, topics) => ({statusId, categoryType, topics}),
    [ActionTypes.ASSIGN_MONTHLY_APPROVAL]: (statusId, topics) => ({statusId, topics}),
    [ActionTypes.APPROVE_MONTHLY_APPROVAL]: (statusId, categoryType, topics) => ({statusId, categoryType, topics}),
    [ActionTypes.PENDING_APPROVALS]: (year, auditId, facility) => ({year, auditId, facility}),
    [ActionTypes.ADD_REVIEW_COMMENTS]: (statusId, topics, comments) => ({statusId, topics, comments}),
    [ActionTypes.RESET_APPROVAL_SUMMARY]: () => ({}),
    [ActionTypes.RESET_APPROVAL_DETAILS]: () => ({}),
    [ActionTypes.UPDATE_AUDIT_TYPES]: (auditId, selfAudit) => ({auditId, selfAudit}),
    [ActionTypes.GET_APPROVAL_EMISSION]: (statusId, emissionType, categoryType) => ({statusId, emissionType, categoryType}),
    [ActionTypes.GET_TOPIC_BASED_APPROVAL]: (statusId, emissionType, categoryType) => ({statusId, emissionType, categoryType}),
    [ActionTypes.ASSIGN_TO_SUBMIT]: (statusId, categoryType, topics) => ({statusId, categoryType, topics}),
    [ActionTypes.PENDING_APPROVAL_EMISSION]: (statusId, emissionType, categoryType) => ({statusId, emissionType, categoryType}),
    [ActionTypes.GET_APPROVED_DATA]: (statusId, categoryType, topics) => ({statusId, categoryType, topics}),
    [ActionTypes.GET_OFFSET_IOT_DATA_DETAILS]: (id,year,period) => ({id,year,period}),
    [ActionTypes.GET_OVERALL_APPROVAL_STATUS]: (facilityId,year,auditCycle) => ({facilityId,year,auditCycle}),
    [ActionTypes.GET_OVERVIEW_APPROVAL_STATUS]: (facilityId,year,auditCycle) => ({facilityId,year,auditCycle}),
    [ActionTypes.GET_OVERALL_REQUEST_APPROVAL_STATUS]: (facilityId,year,auditCycle) => ({facilityId,year,auditCycle}),
    [ActionTypes.REVIEW_OFFSET_DATA]: (programId,year,period,data) => ({programId,year,period,data}),
    [ActionTypes.APPROVE_OFFSET_DATA]: (programId,year,period,data) => ({programId,year,period,data}),
    [ActionTypes.SUBMIT_OFFSET_DATA]: (programId,year,period,data) => ({programId,year,period,DataArrayRounded}),
    [ActionTypes.GET_OFFSET_APPROVAL_STATUS]: (facilityId,year,auditCycle) => ({facilityId,year,auditCycle}),
    [ActionTypes.RESET_REVIEW_OFFSET_STATUS]: () => ({}),
    [ActionTypes.RESET_APPROVE_OFFSET_STATUS]: () => ({}),
    [ActionTypes.RESET_SUBMIT_OFFSET_STATUS]: () => ({}),

})

