import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  Box,
  CircularProgress,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import DashboardLayout from "../../../layouts/DashboardLayout"
import CeroDropdown from "../../../components/CeroDropdown";
import { sampleYear } from "../../../constants";
import { pendingApprovals } from "../../../redux/actions/approval";
import useStyles from "./styles";
import { getFacilityPeriods } from "../../../redux/actions/listings";
import MonthlySummaryTable from "../RequestApproval/MonthlySummaryTable";
import { getCookie } from "../../../services/cookie";
import { STATUS } from "../../../redux/constants";
import Status from "./Status";
import { getOverallApprovalStatus } from "../../../redux/actions/approval";

const OffsetRequestApproval = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [filterYear, setYear] = useState(new Date().getFullYear());
  const [facility, setFacility] = useState("");

  const facilitiesData = useSelector(
    (state) => state.listings.listFacilities?.data
  );
  const pendingDatas = useSelector(
    (state) => state.approval.pendingApprovals.data
  );
  const pendingOffsetData = useSelector(
    (state) => state.approval.getOverallApprovalStatus?.data?.offset_approval_status_list
  );
  console.log('pendingOffsetData', pendingOffsetData)
  const pendingDatastatus = useSelector(
    (state) => state.approval.getOverallApprovalStatus.status
  );
  const periodsData = useSelector(
    (state) => state.listings.getFacilityPeriods?.data
  );
  const [auditId, setAudit] = useState("");
  const role = getCookie("role");

  const facilitiesList = facilitiesData?.map((item) => ({
    key: item.id,
    value: item.name,
  }));

  const onSelectApprovalSummaryData = (row) => {
    navigate(`details/${row.program_id}`,{state:{year:row.assessment_year,period:row.period,program:row.facility_name}});
  };

  useEffect(() => {
    if (!facility) {
      setFacility(facilitiesList?.[0]?.key);
    }
  }, [facilitiesList, facility]);

  useEffect(() => {
    if (facility && filterYear) {
      dispatch(getFacilityPeriods(filterYear, facility));
    }
  }, [filterYear, facility, dispatch]);

  useEffect(() => {
    setAudit(periodsData?.[0]?.key);
  }, [periodsData, facility]);

  useEffect(() => {
    if(filterYear && auditId && facility){
    dispatch(pendingApprovals(filterYear, auditId, facility));
    dispatch(getOverallApprovalStatus( facility,filterYear,auditId));
  }
  }, [filterYear, auditId, facility, dispatch]);

  return (
    <DashboardLayout>
      <Container className={classes.container}>
        <Grid
          className={classes.filterContainer}
          container
          columnSpacing={2}
          alignItems="center"
          wrap="nowrap"
        >
          <Grid item xs={3} style={{ paddingLeft: 0 }}>
            <CeroDropdown
              id="year"
              label="Assessment Year"
              fullWidth
              options={sampleYear()}
              onChange={({ target }) => setYear(target.value)}
              selectedValue={filterYear}
            />
          </Grid>
          <Grid item xs={3}>
            <CeroDropdown
              id="facility"
              label="Facility"
              fullWidth
              options={facilitiesList}
              onChange={({ target }) => setFacility(target.value)}
              selectedValue={facility}
            />
          </Grid>
          <Grid item xs={3}>
            <CeroDropdown
              id="auditCycle"
              label="Period"
              fullWidth
              options={periodsData}
              onChange={({ target }) => setAudit(target.value)}
              selectedValue={auditId}
            />
          </Grid>
        </Grid>
        {pendingOffsetData?.length !== 0 &&
        (<Status
          year={filterYear}
          facility={facility}
          auditId={auditId}
          // status={approvalSummary?.status}
        />)
        }
        {pendingDatastatus === STATUS.SUCCESS ? (
          <MonthlySummaryTable
            summaryData={pendingOffsetData || []}
            onSelectApprovalSummaryData={onSelectApprovalSummaryData}
          />
        ) : (
          <Box className={classes.loader}>
            <Typography variant="h7" component="span">
              {pendingDatastatus === STATUS.RUNNING ? (
                <CircularProgress />
              ) : pendingDatastatus === STATUS.ERROR ? (
                "We couldn't process your request. Please try again later"
              ) : (
                ""
              )}
            </Typography>
          </Box>
        )}
      </Container>
    </DashboardLayout>
  );
};

export default OffsetRequestApproval;
