import { createActions } from 'redux-actions';

import { ActionTypes } from '../constants/actions';

export const {
    listCarbonOffsetPrograms,
    addCarbonOffset,
    getProgramTypes,
    getPrograms,
    resetCarbonOffset,
    getOffsetDetails,
    getIotData,
    updateIotData,
    resetIotData,
    refreshIotData,
    getOffsetCountries,
    getAuditorCarbonOffsetList,
    getAuditorCarbonOffsetIotData,
    getAuditorCarbonOffsetDetails,
    validateAuditorCarbonOffsetData,
    reviewAuditorCarbonOffsetData,
    resetAuditorReviewOffsetStatus,
    resetAuditorValidateOffsetStatus,
    resetEditOffsetStatus,
    getCarbonOffsetFormDetails,
    updateCarbonOffsetFormDetails,
} = createActions({
    [ActionTypes.LIST_CARBON_OFFSET_PROGRAMS]: (location, program) => ({location, program }),
    [ActionTypes.ADD_CARBON_OFFSET]: (requestData) => ({requestData}),
    [ActionTypes.GET_PROGRAM_TYPES]: ( ) => ({ }),
    [ActionTypes.GET_PROGRAMS]: ( programId ) => ({ programId }),
    [ActionTypes.RESET_CARBON_OFFSET]: () => {},
    [ActionTypes.GET_OFFSET_DETAILS]: ( programId ) => ({ programId }),
    [ActionTypes.GET_IOT_DATA]: ( programId , year) => ({ programId , year}),
    [ActionTypes.UPDATE_IOT_DATA]: ( dataId , requestData ) => ({ dataId, requestData }),
    [ActionTypes.RESET_IOT_DATA]: () => {},
    [ActionTypes.REFRESH_IOT_DATA]: ( programId ) => ({ programId }), 
    [ActionTypes.GET_OFFSET_COUNTRIES]: ( ) => ({ }),
    [ActionTypes.GET_AUDITOR_CARBON_OFFSET_LIST]: (companyId,auditId ) => ({ companyId,auditId }),
    [ActionTypes.GET_AUDITOR_CARBON_OFFSET_IOT_DATA]: (companyId,programId,year,period  ) => ({ companyId,programId,year,period  }),
    [ActionTypes.GET_AUDITOR_CARBON_OFFSET_DETAILS]: (companyId,programId) => ({ companyId,programId}),
    [ActionTypes.VALIDATE_AUDITOR_CARBON_OFFSET_DATA]: (companyId, year, period, programId) => ({ companyId, year, period, programId}),
    [ActionTypes.REVIEW_AUDITOR_CARBON_OFFSET_DATA]: (companyId, year, period, programId, data) => ({ companyId, year, period, programId, data}),
    [ActionTypes.RESET_AUDITOR_REVIEW_OFFSET_STATUS]: () => {},
    [ActionTypes.RESET_AUDITOR_VALIDATE_OFFSET_STATUS]: () => {},
    [ActionTypes.RESET_EDIT_OFFSET_STATUS]: () => {},
    [ActionTypes.GET_CARBON_OFFSET_FORM_DETAILS]: ( id ) => ({ id }), 
    [ActionTypes.UPDATE_CARBON_OFFSET_FORM_DETAILS]: ( id, requestData ) => ({ id, requestData }), 
});
