import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 0,
    margin: 0,
    "@media (min-width: 1200px)": {
      maxWidth: "none",
    },
  },
  backContainer: {
    color: theme.palette.Primary.background,
    textTransform: "capitalize",
    display: "flex",
    paddingBottom: 20,
    alignItems: "center",
    cursor: "pointer",
  },
  box: {
    margin: 20,
    padding: 10,
    border: "1px solid #8b2147",
    borderRadius: "5px",
    display: "flex",
    flexDirection: "column",
    width: "inherit",
  },
  buttonPrimary: {
    background: theme.palette.Primary.background,
    textTransform: "capitalize",
    height: 35,
    borderColor: theme.palette.Primary.background,
    marginRight: 20,
    fontWeight: 400,
    color: theme.palette.background.white,
    "&:hover": {
      backgroundColor: theme.palette.Primary.hoverButton,
      borderColor: theme.palette.Primary.hoverButton,
      color: theme.palette.Primary.light,
    },
  },
  topContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  optionContainer: {
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: 3,
  },
}));

export default useStyles;
