import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  selectContainer: {
    marginBottom: theme.spacing(5),
  },
  formField: {
    margin: theme.spacing(3, 0),
  },
  popUpClass: {
    "& .MuiDialog-container > .MuiPaper-root": {
      backgroundColor: "white !important",
    },
    "& .MuiDialog-container > .MuiPaper-root > .MuiDialogContent-root > .MuiGrid-container > .MuiGrid-item > .MuiBox-root":
      {
        height: "60px",
        marginBottom: "20px !important",
      },
    "& .MuiDialog-container > .MuiPaper-root > .MuiDialogContent-root > .MuiGrid-container > .MuiGrid-item > .MuiBox-root > .MuiFormControl-root > .MuiInputBase-root":
      {
        height: "56px",
      },
    "& .MuiDialog-container > .MuiPaper-root > .MuiDialogContent-root > .MuiGrid-container > .MuiGrid-item > .MuiBox-root > .MuiFormControl-root > .MuiInputLabel-outlined":
      {
        marginTop: "0px !important",
      },
    "& .MuiDialog-container > .MuiPaper-root > .MuiDialogContent-root > .MuiGrid-container > .MuiGrid-item > .MuiBox-root > .MuiFormControl-root > .MuiFormLabel-filled":
      {
        marginTop: "0px !important",
      },
  },
  buttonPrimary: {
    background: theme.palette.Primary.background,
    "&:hover": {
      backgroundColor: theme.palette.Primary.hoverButton,
      color: theme.palette.Primary.light,
    },
    width: 120,
    height: 42,
    fontSize: 14,
    marginRight: theme.spacing(3),
    marginTop: 0,
    fontWeight: 400,
    textTransform: "capitalize",
    marginTop:theme.spacing(5),
  },
  buttonSecondary: {
    color: theme.palette.Primary.background,
    borderColor: theme.palette.Primary.background,
    textTransform: "capitalize",
    width: 120,
    height: 42,
    "&:hover": {
      color: theme.palette.Primary.light,
      borderColor: theme.palette.Primary.background,
      backgroundColor: theme.palette.Primary.background,
    },
    marginRight: theme.spacing(5),
    marginTop:theme.spacing(5),
  },
  secondaryBtnText: {
    color: theme.palette.Primary.main,
    fontWeight: 600,
    fontSize: 14,
  },
  uploadButton: {
    backgroundColor: theme.palette.Primary.background,
    textTransform: "capitalize",
    fontWeight: 450,
    marginRight: 15,
    marginTop: 10,
    marginBottom: 10,
    width: 190,
    height: 42,
    "&:hover": {
        backgroundColor: theme.palette.Primary.hoverButton,
        color: theme.palette.Primary.light,
      },
      "& .MuiButton-startIcon":{
        transform: 'rotate(115deg)'
      }
}
}));

export default useStyles;
